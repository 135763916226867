// classes
.left {
    float: left;
}

.right {
    float: right;
}

.block {
    display: block;
}

.none {
    display: none;
}

.inline-block {
    display: inline-block;
    vertical-align: middle;
}

.inline {
    display: inline;
}

.center {
    margin-left: auto;
    margin-right: auto;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.static {
    position: static;
}

.clearfix {
    .clearfix();
}

.cleaner {
    clear: both;
}

.cleaner-left {
    clear: left;
}

.cleaner-right {
    clear: right;
}

.strong {
    font-weight: 700;
}

.italic {
    font-style: italic;
}

.text-justify {
    text-align: justify;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-uppercase {
    text-transform: uppercase;
}

.u--left {
    float: left !important;
}

.u--right {
    float: right !important;
}

.u--block {
    display: block !important;
}

.u--none {
    display: none !important;
}

.u--inline-block {
    display: inline-block !important;
}

.u--inline {
    display: inline !important;
}

.u--center {
    margin-left: auto !important;
    margin-right: auto !important;
}

.u--relative {
    position: relative !important;
}

.u--absolute {
    position: absolute !important;
}

.u--static {
    position: static !important;
}

.u--cleaner {
    clear: both !important;
}

.u--cleaner-left {
    clear: left !important;
}

.u--cleaner-right {
    clear: right !important;
}

.u--strong {
    font-weight: 700 !important;
}

.u--italic {
    font-style: italic !important;
}

.u--text-justify {
    text-align: justify !important;
}

.u--text-left {
    text-align: left !important;
}

.u--text-right {
    text-align: right !important;
}

.u--text-center {
    text-align: center !important;
}

.u--text-uppercase {
    text-transform: uppercase !important;
}