.footer {
  .fs(10);
  .ff-light();
  color: @white;
  background-color: #292a2d;
  padding: 20px;
  @media (max-width: @screen-sm-max) {
    text-align: center;
  }
  & p {
    margin: 0;
    @media (min-width: @screen-md) {
      float: left;
    }
  }
  & ul {
    list-style: none;
    padding: 0;
    margin: 0 -8px;
    @media (max-width: @screen-sm-max) {
      margin-bottom: 20px;
    }
    @media (min-width: @screen-md) {
      float: right;
    }
    & li {
      display: inline-block;
      padding: 0;
      margin: 0;
      & a {
        padding: 0 8px;
        color: @white;
        text-decoration: none;
      }
    }
  }
}
