// grid system

.container {
    .container-fixed();
    box-sizing: border-box;
    max-width: @screen-max-width;
}

.row {
    .make-row();
}

.make-grid-columns();

.make-grid(is);
@media (min-width: @screen-xs) {
    .make-grid(xs);
}
@media (min-width: @screen-ts) {
    .make-grid(ts);
}

@media (min-width: @screen-sm) {
    .make-grid(sm);
}

@media (min-width: @screen-md) {
    .make-grid(md);
}

@media (min-width: @screen-lg) {
    .make-grid(lg);
}

// grid system
.calc-grid-column(@index, @class, @type) when (@type = width) and (@index > 0) {
    .col--@{class}--@{index} {
        width: percentage((@index / @grid-columns));
    }
}

.loop-grid-columns(@index, @class, @type) when (@index >= 0) {
    .calc-grid-column(@index, @class, @type);
    .loop-grid-columns((@index - 1), @class, @type);
}

.float-grid-columns(@class) {
    .col(@index) when (@index = 1) { // initial
        @item: ~".col--@{class}--@{index}";
        .col((@index + 1), @item);
    }
    .col(@index, @list) when (@index =< @grid-columns) { // general
        @item: ~".col--@{class}--@{index}";
        .col((@index + 1), ~"@{list}, @{item}");
    }
    .col(@index, @list) when (@index > @grid-columns) { // terminal
        @{list} {
            float: left;
        }
    }
    .col(1);
}

.make-grid(@class) {
    .float-grid-columns(@class);
    .loop-grid-columns(@grid-columns, @class, width);
    .loop-grid-columns(@grid-columns, @class, pull);
    .loop-grid-columns(@grid-columns, @class, push);
    .loop-grid-columns(@grid-columns, @class, offset);
}

.make-grid-columns() {
    .col(@index) when (@index = 1) { // initial
        @item: ~".col--is--@{index}, .col--xs--@{index}, .col--ts--@{index}, .col--sm--@{index}, .col--md--@{index}, .col--lg--@{index}";
        .col((@index + 1), @item);
    }
    .col(@index, @list) when (@index =< @grid-columns) { // general; "=<" isn't a typo
        @item: ~".col--is--@{index}, .col--xs--@{index}, .col--ts--@{index}, .col--sm--@{index}, .col--md--@{index}, .col--lg--@{index}";
        .col((@index + 1), ~"@{list}, @{item}");
    }
    .col(@index, @list) when (@index > @grid-columns) { // terminal
        @{list} {
            box-sizing: border-box;
            position: relative;
            min-height: 1px;
            padding-left: (@grid-gutter-width / 2);
            padding-right: (@grid-gutter-width / 2);
        }
    }
    .col(1);
}

.make-row(@gutter: @grid-gutter-width) {
    margin-left: (@gutter / -2);
    margin-right: (@gutter / -2);
    &:extend(.clearfix all);
}

.container-fixed() {
    margin-right: auto;
    margin-left: auto;
    padding-left: (@grid-gutter-width / 2);
    padding-right: (@grid-gutter-width / 2);
    &:extend(.clearfix all);
}


