.screenwidth {
    display: none;
    @media (max-width: @screen-is-max) {
        width: 1px;
    }
    @media (min-width: @screen-xs) and (max-width: @screen-xs-max) {
        width: 2px;
    }
    @media (min-width: @screen-ts) and (max-width: @screen-ts-max) {
        width: 3px;
    }
    @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
        width: 4px;
    }
    @media (min-width: @screen-md) and (max-width: @screen-md-max) {
        width: 5px;
    }
    @media (min-width: @screen-lg) {
        width: 6px;
    }
}