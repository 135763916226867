
.top__bar {

  @media (min-width: @screen-xs) {
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
  }
}

.top__bar__items {
  @media (max-width: @screen-is-max) {
    margin: 0 auto;
    max-width: 314px;
  }
  @media (min-width: @screen-xs) {
    float: right;
  }
}

.top__bar__items__item {
  .fs(14);
  float: left;
  @media (min-width: @screen-xs) {
    border-left: 1px solid @gray;
  }
}

.top__bar__items__item__1 {
  & span {
    display: block;
    cursor: pointer;
    position: relative;
    @media (max-width: @screen-is-max) {
      padding: 9px 8px 8px 8px;
    }
    @media (min-width: @screen-xs) {
      padding: 9px 35px 8px 15px;
    }
    &:after {
      content: '';
      position: absolute;
      background: data-uri('../img/login_dropdown.svg') center center no-repeat;
      width: 9px;
      height: 5px;
      top: 50%;
      margin-top: -2.5px;
      right: 15px;
      @media (max-width: @screen-is-max) {
        display: none;
      }
    }
    & img {
      line-height: 1;
    }
  }
}

.top__bar__items__item__2 {
  & a {
    padding: 9px 12px;
    color: @green;
    display: block;
    .ff-bold();
    line-height: 1;
    @media (max-width: @screen-is-max) {
      border-left: 1px solid @gray;
    }
  }
}

.top__bar__items__item__3 {
  border-left: 1px solid @gray;
  @media (min-width: @screen-xs) {
    border-right: 1px solid @gray;
  }
  & span {
    text-decoration: underline;
    .fs(13);
    line-height: 1;
    padding: 10px 30px 9px 35px;
    display: block;
    position: relative;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      background: data-uri('../img/login_user.svg') center center no-repeat;
      width: 11px;
      height: 12px;
      background-size: 11px 12px;
      left: 15px;
      top: 50%;
      margin-top: -6px;
    }
    &:after {
      content: '';
      position: absolute;
      background: data-uri('../img/login_dropdown.svg') center center no-repeat;
      width: 9px;
      height: 5px;
      right: 15px;
      top: 50%;
      margin-top: -2.5px;
    }
  }
}

.header__bar {
  clear: both;
  background-color: @white;
  border-bottom: 1px solid @gray;
  border-top: 1px solid @gray;
  overflow: auto;
}

.header__bar__logo__corp {
  //display: none;
  //margin: 16px 0;
  float: left;
  width: 30%;
  position: relative;
  height: 94px;
  img {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-height: 88px;
  }
  @media (max-width: @screen-ts-max) {
    width: 50%;
  }
  @media (min-width: @screen-sm) {
    width: 30%;
  }
}

.header__bar__logo__headline {
  .fs(12);
  //max-width: 50%;
  //float: right;
  margin-bottom: 5px;
  display: block;
}

.header__bar__logo {
  margin: 14px 0;
  @media (max-width: @screen-ts-max) {
    text-align: center;
  }
  &.corp {
    float: left;

    margin: 21px 0;
    img {
      max-width: 118px;
    }
  }
  @media (max-width: @screen-ts-max) {
    width: 50%;
  }
  @media (min-width: @screen-sm) {
    padding-left: 20px;
  }
}

.header__bar__middle {
  text-align: center;
  & h1 {
    .fs(23);
    text-transform: uppercase;
    line-height: 1;
    .ff-bold();
    margin-top: 20px;
    margin-bottom: 10px;
  }
  & h2 {
    .fs(15);
    .ff-normal();
    line-height: 1;
    margin-bottom: 25px;
    margin-top: 0;
  }
  @media (max-width: @screen-is-max) {
    display: none;
  }
}

.header__bar__corp {
  @media (max-width: @screen-ts-max) {
    text-align: center;
  }
  @media (min-width: @screen-sm) {
    text-align: right;
  }
  & a {
    border: 1px solid @gray;
    border-radius: 5px;
    padding: 15px 28px 15px 58px;
    margin: 22px 0;
    display: inline-block;
    .fs(15);
    line-height: 1;
    color: black;
    text-decoration: none;
    .ff-bold();
    position: relative;
    &:before {
      content: '';
      position: absolute;
      background: url('../img/corp_login.png') center center no-repeat;
      width: 20px;
      height: 22px;
      left: 25px;
      background-size: 20px 22px;
      top: 50%;
      margin-top: -11px;
    }
    &.corp {
      &:before {
        background: url('../img/go_to.svg') center center no-repeat;
      }
    }
  }
}