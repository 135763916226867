.filter__items {
  background-color: @light-blue;
  h2 {
    .fs(24);
    margin-top: 20px;
  }
  span {
    .ff-bold();
  }
}

.filter__items__wrapper {
  padding-bottom: 30px;
}

.filter__find {
  padding-top: 20px;
}

.filer__find__left {
  border: 1px solid @gray;
}

.filter__find__left__logo {
  text-align: center;
  padding: 25px 0;
  border-bottom: 1px solid @gray;
  max-height: 85px;
}

.filter__find__left__info {
  padding: 20px;
  h3 {
    color: @orange;
    .fs(20);
    .ff-bold();
  }
  p {
    .fs(13);
  }
  strong {
    .fs(13);
  }
  a {
    .fs(13);
    color: @orange;
    &:hover {
      color: darken(@orange, 7%);
    }
  }
}

.filter__find__open_hours {
  padding-top: 20px;
  display: block;
}

.filter__find__search {
  position: relative;
  background-color: @gray;
  padding: 10px;
  form {
    margin: 0;
  }
}

.filter__find__searchfield {
  input {
    height: 50px;
    border-radius: 4px;
    border: none;
    width: 80%;
    padding-left: 55px;
  }
  &:before {
    content: '';
    position: absolute;
    background: data-uri('../img/filter-search.svg') no-repeat;
    height: 20px;
    width: 20px;
    top: 50%;
    margin-top: -10px;
    left: 25px;
  }
}

.filter__find__submit {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 50px;
  background-color: @orange;
  width: 20%;
  color: @white;
  .ff-bold();
  border: none;
  border-radius: 4px;
}

.filter__notif {
  padding: 20px;
  margin-top: 10px;
  p {
    margin: 0;
    line-height: 1;
    .fs(14);
  }
}

.filter__notif--true {
  background-color: #ddf1b6;
  color: #4f6327;
}

.filter__notif--false {
  color: #c1393d;
  background-color: #ffc2c4;
}

.filter__found__button {
  padding: 15px 20px;
  line-height: 1;
}

.filter__found {
  margin-top: 20px;
  li {
    list-style-type: none;
    position: relative;
    border-top: 1px solid @gray;
    border-left: 1px solid @gray;
    border-right: 1px solid @gray;
    padding: 0;
    margin: 0;
    display: block;
    .clearfix;
    &:nth-of-type(even) {
      background-color: #f6f6f6;
    }
  }
}

.filter__found__item {
  @media (min-width: @screen-ts) {
    float: left;
  }
  @media (min-width: @screen-sm) {
    width: 70%;
  }
  &.active {
    width: 100%;
  }
}

.filter__found__button {
  @media (max-width: @screen-sm-max) {
    text-align: center;
    margin: 0 auto;
    clear: both;
    display: block;
  }
  @media (min-width: @screen-md) {
    position: absolute;
    right: 30px;
    margin-top: -40px;
    max-width: 30%;
    top: 50%;
  }
}

.filter__found__image {
  text-align: center;
  &.active {
    width: 30%;
    padding: 30px;
    position: relative;
    height: 290px;
    img {
      margin: auto;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 30px;
      max-height: 290px;
      max-width: 90%;
      height: auto;
    }
  }
  img {
    height: 120px;
    @media (min-width: @screen-ts) {
      position: absolute;
    }
  }
  @media (min-width: @screen-ts) {
    float: left;
  }
}

.filter__found__basic {
  vertical-align: top;
  padding-top: 20px;
  line-height: 1;
  .fs(19);
  .ff-bold();
  color: @black;
  h4 {
    margin-bottom: 12px;
    line-height: 1.2;
    padding: 0;
    @media (max-width: @screen-xs-max) {
      text-align: center;
    }
  }
  p {
    margin-bottom: 15px;
    line-height: 1;
    .ff-normal();
  }
  &.active {
    padding-left: 30px;
    //float: none;
  }
  @media (min-width: @screen-ts) {
    padding-left: 190px;
    float: left;
  }
}

.filter__found__infos {
  @media (max-width: @screen-xs-max) {
    padding-left: 40px;
    padding-right: 15px;
  }
}

.filter__found__date,
.filter__found__place {
  position: relative;
  color: @dark-gray;
  @media (max-width: @screen-xs-max) {
    max-width: 300px;
    margin: 0 auto;
  }
  @media (min-width: @screen-ts) {
    margin-left: 30px;
    padding: 0;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    margin-left: -30px;
    width: 14px;
  }
}

.filter__found__date {
  &:before {
    background: data-uri('../img/calendar.png') no-repeat;
    background-size: 14px;
    height: 14px;
  }
}

.filter__found__place {
  &:before {
    background: data-uri('../img/place.png') no-repeat;
    background-size: 14px 17px;
    height: 17px;
  }
}

.filter__item__closer {
  position: absolute;
  right: 5px;
  top: 5px;
}