// custom mixins
.ff-light() {
    font-weight: 300;
}

.ff-normal() {
    font-weight: 400;
}

.ff-bold() {
    font-weight: 700;
}
// end custom mixins

.clearfix() {
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

.grayscale() {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%);
}

.grayscale-remove() {
    filter: none;
    -webkit-filter: grayscale(0);
}

.placeholder(@color) {
    &::-moz-placeholder {
        color: @color;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color: @color;
    }
    &::-webkit-input-placeholder {
        color: @color;
    }
}

.fs(@value) {
    @remValue: (@value / @fs-base);
    font-size: ~"@{value}px";
    font-size: ~"@{remValue}rem";
}

.mtem(@value) {
    @emValue: (@fs-base / @value);
    & when (@value < @fs-base) {
        @emValue: (@value / @fs-base);
    }
    margin-top: ~"@{emValue}em";
}

.mbem(@value) {
    @emValue: (@fs-base / @value);
    & when (@value < @fs-base) {
        @emValue: (@value / @fs-base);
    }
    margin-bottom: ~"@{emValue}em";
}