
img {
  max-width: 100%;
  height: auto;
}

button,
.button {
  transition-duration: 0.3s;
  display: inline-block;
  text-align: center;
  border-width: 0;
  padding: 18px 24px;
  text-decoration: none;
  font-weight: normal;
  color: @white;
  cursor: pointer;
  background-color: @black;
  border-radius: 5px;
  &:hover {
    text-decoration: none;
    color: @white;
    background-color: @black-8;
  }
}

.button--orange {
  background-color: @orange;
  &:hover {
    background-color: darken(@orange, 7%);
  }
}

.button--green {
  background-color: @green;
  &:hover {
    background-color: darken(@green, 7%);
  }
}

a {
  cursor: pointer;
}

p {
  .fs(15);
  line-height: 1.7;
  margin: 0 0 1em;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 1em;
  margin-left: 0;
  margin-right: 0;
  line-height: 1.15;
  font-weight: 400;
  &:first-child {
    margin-top: 0;
  }
}

h1,
.h1 {
  .fs(43);
  .mbem(43);
}

h2,
.h2 {
  .fs(35);
  .mbem(35);
}

h3,
.h3 {
  .fs(27);
  .mbem(27);
}

h4,
.h4 {
  .fs(23);
  .mbem(23);
}

h5,
.h5 {
  .fs(19);
  .mbem(19);
}

h6,
.h6 {
  .fs(17);
  .mbem(17);
}

ul {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  .mbem(16);
  padding: 0;
  // list-style: none;
  li {
    .fs(15);
    line-height: 1.7;
    padding: 0 0 0 16px;
    color: #7f7f7e;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    // background: data-uri('../images/icon-ul-yellow.png') left 8px no-repeat;
    .mbem(30);
  }
  ol,
  ul {
    .mtem(30);
    margin-bottom: 0;
  }
  ol {
    li {
      background: none;
    }
  }
}

ol {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  .mbem(16);
  padding: 0 0 0 11px;
  li {
    .fs(15);
    line-height: 1.7;
    padding: 0 0 0 5px;
    color: #7f7f7e;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    .mbem(30);
  }
  ol,
  ul {
    .mtem(30);
    margin-bottom: 0;
  }
  ul {
    li {
      padding-left: 16px;
    }
  }
}

blockquote {
  margin: 0;
  padding-left: 68px;
  // background: data-uri('../images/blockquote.png') left 8px no-repeat;
}

blockquote,
blockquote p {
  .fs(15);
  font-style: italic;
  text-align: left;
  line-height: 1.6;
}

.button__active {
  &.active {
    border: 1px solid @orange;
    box-shadow: inset 0px 0px 0 1px @orange;
  }
}