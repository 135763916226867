// form
/*
input[type="radio"] {
  display: none;
}

input[type=radio]:checked + label:after {
  content: '';
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: @orange;
  font-size: 40px;
  text-align: center;
  line-height: 18px;
  @media (max-width: @screen-is-max) {
    left: 15px;
    top: 15px;
  }
  @media (min-width: @screen-xs) {
    left: 25px;
    top: 20px;
  }
}
*/
.radio,
.checkbox {
  opacity: 0;
  position: absolute;
}

.checkbox, .checkbox__label, .radio, .radio__label {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  cursor: pointer;
}

.checkbox__label, .radio__label {
  position: relative;
}

.checkbox + .checkbox__label:before, .radio + .radio__label:before {
  content: '';
  background: #fff;
  border: 1px solid @gray;
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 2px;
  margin-right: 10px;
  text-align: center;
  margin-bottom: -5.5px;
}

.checkbox:checked + .checkbox__label:before {
  background: @orange;
  box-shadow: inset 0px 0px 0px 4px #fff;
}

.radio + .radio__label:before {
  border-radius: 50%;
}

.radio:checked + .radio__label:before {
  background: @orange;
  box-shadow: inset 0px 0px 0px 4px #fff;
}


.checkbox:focus + .checkbox__label, .radio:focus + .radio__label {
  outline: 1px solid #ddd; /* focus style */
}

.input__file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.input__error {
  clear: both;
  p {
    width: 100%;
    display: block;
    position: relative;
    margin-top: 10px;
    border-radius: 3px;
    background-color: #ffc2c4;
    padding: 10px 0;
    color: #c1393d !important;
    .ff-bold();
    line-height: 1.3;
    .fs(12);
    span {
      display: block;
      margin: 0 40px;
      text-align: left;
    }
    &:before {
      content: '';
      position: absolute;
      background: data-uri('../img/input_error.svg') center center no-repeat;
      left: 10px;
      top: 50%;
      margin-top: -8px;
      background-size: 16px;
      width: 16px;
      height: 16px;
    }
  }
}