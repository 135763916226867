.tabs__background > .container {
  @media (max-width: @screen-ts-max) {
    padding: 0;
  }
}

.tab__box {
  position: relative;
  padding-top: 32px;
  padding-bottom: 64px;
  &:before {
    content: '';
    position: absolute;
    background-image: url('../img/hp_wallet.png');
    background-size: 821/3.5px 448/3.5px;
    left: -821/3.5px;
    background-repeat: no-repeat;
    top: 70%;
    right: 0;
    width: 821/3.5px;
    height: 448/3.5px;
    @media (max-width: @screen-md-max) {
      display: none;
    }
  }
  &:after {
    content: '';
    position: absolute;
    background-image: url('../img/hp_umbrella.png');
    background-size: 291/2px 1105/2px;
    right: -291/2px;
    background-repeat: no-repeat;
    top: 15%;
    width: 291/2px;
    height: 1105/2px;
    @media (max-width: @screen-md-max) {
      display: none;
    }
  }
}

.tabs__background {
  overflow: hidden;
  background-image: url('../img/tabs_background.png');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  position: relative;
  padding-bottom: 64px;
}

.tab__box__wrapper {
  display: flex;
}

.tab__box__wrapper__one {
  float: left;
  margin-top: 15px;
  display: inline-flex;
  text-transform: uppercase;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.23);
  background-color: @white;
  vertical-align: bottom;
  position: relative;
  @media (max-width: @screen-xs-max) {
    width: 50%;
  }
  @media (min-width: @screen-ts) {
    width: 33.33%;
  }
  & h3 {
    .ff-bold();
    cursor: pointer;
    line-height: 1;
    text-decoration: underline;
    margin: 0;
    position: relative;
    transition-duration: 0.3s;
    @media (max-width: @screen-is-max) {
      .fs(14);
      padding: 20px 0 20px 50px;
    }
    @media (min-width: @screen-xs) {
      padding: 24px 0 24px 78px;
    }
    @media (min-width: @screen-xs) and (max-width: @screen-ts-max) {
      .fs(15);
    }
    @media (min-width: @screen-sm) {
      .fs(18);
    }
    & span {
      color: #595959;
      .ff-normal();
      text-transform: none;
      padding-top: 7px;
      padding-right: 5px;
      text-decoration: none;
      line-height: 1.2;
      display: inline-block;
      @media (max-width: @screen-is-max) {
        .fs(12);
      }
      @media (min-width: @screen-xs) and (max-width: @screen-ts-max) {
        .fs(12);
      }
      @media (min-width: @screen-sm) {
        .fs(14);
      }
    }
    &:before {
      content: '';
      position: absolute;
      left: 20px;
      top: 50%;
      margin-top: -20px;
      width: 40px;
      height: 40px;
      @media (max-width: @screen-is-max) {
        left: 10px;
        margin-top: -15px;
        width: 30px;
        height: 30px;
      }
      @media (min-width: @screen-xs) {
        left: 20px;
        margin-top: -20px;
        width: 40px;
        height: 40px;
      }
    }
  }
  &.active {
    padding-top: 15px;
    margin: 0;
    z-index: 20;
    & h3 {
      &:after {
        content: '';
        position: absolute;
        height: 10px;
        background-color: @white;
        width: 100%;
        bottom: -10px;
        left: 0;
        z-index: 20;
      }

    }
  }
}

.tab__box__wrapper__one__1 {
  & h3 {
    &:hover {
      text-decoration: none;
      color: @blue-button;
    }
    &:before {
      background-image: url('../img/tab1.png');
      background-repeat: no-repeat;
      z-index: 1;
      @media (max-width: @screen-is-max) {
        background-size: 30px;
      }
      @media (min-width: @screen-xs) {
        background-size: 40px;
      }
    }
  }
  &.active {
    h3 {
      text-decoration: none;
      color: @blue-button;
    }
  }
}

.tab__box__wrapper__one__2 {
  z-index: 2;
  & h3 {
    &:hover {
      text-decoration: none;
      color: #e15c61;
    }
    &:before {
      background-image: url('../img/tab2.png');
      background-repeat: no-repeat;
      z-index: 1;
      @media (max-width: @screen-is-max) {
        background-size: 30px;
      }
      @media (min-width: @screen-xs) {
        background-size: 40px;
      }
    }
  }
  &.active {
    h3 {
      text-decoration: none;
      color: #e15c61;
    }
  }
}

.tab__box__wrapper__one__3 {
  @media (max-width: @screen-xs-max) {
    display: none;
  }
  & h3 {
    &:hover {
      text-decoration: none;
      color: #84c011;
    }
    &:before {
      background-image: url('../img/tab3.png');
      background-size: 40px;
      background-repeat: no-repeat;

    }
  }
}

#found.tab__box__holder {
  @media (max-width: @screen-is-max) {
    padding: 20px 0;
  }
  @media (min-width: @screen-xs) {
    padding: 20px 25px;
  }
}

.tab__box__holder {
  clear: both;
  background-color: @white;
  box-shadow: 0 -3px 16px #aaa;
  z-index: 10;
  position: relative;
}

#tab__box #content #cooperation {
  background-color: @light-blue;
  clear: both;
  margin: 0;
  text-align: center;
}

#tab__box #content #cooperation p {
  padding-top: 16px;
}

/*
#tab__box #content #cooperation img {
    margin: 32px 16px;
}
*/
.partners__wrapper__text {
  @media (max-width: @screen-is-max) {
    .fs(13);
  }
}

.partners__wrapper__outer {
  padding: 10px 10px 32px;
}

.partners__wrapper {
  @media (max-width: @screen-xs-max) {
    text-align: center;
    .clearfix();
  }
  @media (min-width: @screen-ts) {
    display: table;
    width: 100%;
  }
}

.partners__wrapper__cell {
  @media (max-width: @screen-is-max) {
    width: 50%;
  }
  @media (min-width: @screen-xs) and (max-width: @screen-xs-max) {
    width: 33%;
  }
  @media (max-width: @screen-xs-max) {
    box-sizing: border-box;
    float: left;
    padding: 10px;
    height: 80px;
  }
  @media (min-width: @screen-ts) {
    display: table-cell;
    padding: 0 10px;
  }
}

.lost__items__wrapper {
  .clearfix();
  @media (max-width: @screen-is-max) {
    margin: 0 -4px;
  }
  @media (min-width: @screen-xs) {
    margin: 0 -8px;
  }
}

.lost__what__wrapper {
  h4 {
    .fs(24);
    //line-height: 24px;
    span {
      .ff-bold();
      //  .fs(24);
    }
  }
  position: relative;
  @media (max-width: @screen-is-max) {
    padding: 10px 15px 20px;
  }
  @media (min-width: @screen-xs) {
    padding: 20px 30px;
  }
}

.lost__what__wrapper__headline {
  margin-top: 0;
}

.lost__item {
  float: left;
  box-sizing: border-box;
  @media (min-width: @screen-xs) {
    padding: 8px;
  }
  @media (max-width: @screen-is-max) {
    padding: 4px;
    width: 33.333%;
  }
  @media (min-width: @screen-xs) and (max-width: @screen-xs-max) {
    width: 25%;
  }
  @media (min-width: @screen-ts) and (max-width: @screen-sm-max) {
    width: 25%;
  }
  @media (min-width: @screen-md) {
    width: 14.28%;
  }
  &:nth-of-type(n+4) {
    @media (max-width: @screen-is-max) {
      width: 25%;
    }
  }
}

.lost__item__inner {
  border: 1px solid @gray;
  border-radius: 5px;
  cursor: pointer;
  padding: 8px;
  height: 120px;
  text-align: center;
  background-color: @white;
  @media (max-width: @screen-is-max) {
    .fs(10);
  }
  @media (min-width: @screen-xs) {
    .fs(14);
  }
  span {
    display: block;
    padding-top: 7px;
  }
  &.active {
    border: 1px solid @orange;
    box-shadow: inset 0px 0px 0 1px #f37e33;
  }
}

.lost__item__img__wrap {
  height: 68px;
  position: relative;
  span {
  }
}

.lost__item {
  & img {
    display: block;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-height: 55px;
  }
}

.where__section {
  @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
    max-width: 690px;
  }
  @media (min-width: @screen-md) and (max-width: @screen-md-max) {
    max-width: 800px;
  }
  @media (min-width: @screen-sm) and (max-width: @screen-md-max) {
    .clearfix();
    margin: 0 auto;
  }
}

.where__section h4 {
  @media (max-width: @screen-ts-max) {
    text-align: center;
  }
}

.where__section__inner {
  @media (min-width: @screen-sm) {
    float: left;
  }
}

.where__section__inner--left {
  @media (min-width: @screen-xs) and (max-width: @screen-xs-max) {
    margin: 0 auto;
    max-width: 390px;
  }
  @media (min-width: @screen-ts) and (max-width: @screen-ts-max) {
    margin: 0 auto;
    max-width: 460px;
  }
}

.where__button {
  border: 1px solid @gray;
  border-radius: 5px;
  background-color: @white;
  cursor: pointer;
  @media (max-width: @screen-is-max) {
    display: block;
    margin: 0 0 5px;
  }
  @media (min-width: @screen-xs) {
    display: inline-block;
    vertical-align: middle;
  }
  &.active {
    border: 1px solid @orange;
    box-shadow: inset 0px 0px 0 1px @orange;
  }
  & span {
    line-height: 1;
    display: block;
    position: relative;
    .ff-bold();
    @media (max-width: @screen-xs-max) {
      .fs(12);
    }
    @media (min-width: @screen-ts) {
      .fs(14);
    }
    &:before {
      content: '';
      position: absolute;
      top: 50%;

    }
  }
}

.where__button--1 {
  & span {
    @media (max-width: @screen-xs-max) {
      padding: 12px 15px 12px 60px;
    }
    @media (min-width: @screen-ts) and (max-width: @screen-sm-max) {
      padding: 12px 20px 12px 60px;
    }
    @media (min-width: @screen-md) {
      padding: 12px 10px 12px 60px;
    }
    &:before {
      margin-top: -17.5px;
      background-image: url('../img/town.png');
      width: 35px;
      height: 35px;
      background-size: 35px;
      left: 14px;
    }
  }
}

.where__button--2 {
  & span {
    @media (max-width: @screen-xs-max) {
      padding: 18px 15px 18px 60px;
    }
    @media (min-width: @screen-ts) {
      padding: 19px 25px 19px 60px;
    }
    &:before {
      margin-top: -13px;
      background-image: url('../img/transit.png');
      width: 38px;
      height: 26px;
      background-size: 38px 26px;
      left: 12px
    }
  }
}

.where__button--3 {
  & span {
    @media (max-width: @screen-is-max) {
      padding: 18px 15px 18px 60px;
    }
    @media (min-width: @screen-xs) and (max-width: @screen-xs-max) {
      padding: 18px 15px 18px 34px;
    }
    @media (min-width: @screen-ts) {
      padding: 19px 30px 19px 50px;
    }
    &:before {
      margin-top: -11px;
      background-image: url('../img/where_question_mark.png');
      width: 13px;
      height: 22px;
      background-size: 13px 22px;
      @media (max-width: @screen-is-max) {
        left: 25px;
      }
      @media (min-width: @screen-xs) {
        left: 18px;
      }

    }
  }
}

.where__button--4 {
  select {
    height: 52px;
    width: 100%;
    -webkit-appearance: none;
    padding-left: 15px;
  }
  @media (max-width: @screen-is-max) {
    text-align: center;
  }
  p {
    @media (max-width: @screen-is-max) {
      margin: 7px 0;
    }
  }
}

.found__button--5 {
  text-align: center;
  p {
    padding: 6px 20px;
    margin: 0;
    display: block;
    text-align: center;
    .ff-bold();
    span {
      .ff-light();
    }
  }
  span {
    .ff-light();
  }
}

.where__section__wrap {
  margin: 15px 0 0;
  .clearfix();
}

.where__section__place {
  @media (max-width: @screen-ts-max) {
    margin: 15px auto 0;
    max-width: 300px;
  }
  @media (min-width: @screen-sm) {
    margin-left: 20px;
    float: left;
  }
  select {
    height: 54px;
    padding-left: 15px;
    -webkit-appearance: none;
    @media (max-width: @screen-ts-max) {
      width: 100%;
    }
    @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
      width: 198px;
    }
    @media (min-width: @screen-md) {
      width: 275px;
    }
  }
}

.where__section__send {
  max-width: 300px;
  width: 100%;
  @media (max-width: @screen-md-max) {
    margin: 0 auto;
  }
  @media (min-width: @screen-lg) {
    float: right;
  }
  button {
    .fs(30);
    line-height: 1;
    border: none;
    display: block;
    box-sizing: border-box;
    color: @white;
    .ff-bold();
    border-radius: 4px;
    width: 100%;
    background-color: @orange;
    @media (max-width: @screen-is-max) {
      padding: 13px 20px;
    }
    @media (min-width: @screen-xs) {
      padding: 13px 38px;
    }
    @media (max-width: @screen-md-max) {
      margin: 15px 0 0;
      width: 100%;
    }
    @media (min-width: @screen-lg) {
      margin: 32px 0 0 0;
    }
    span {
      margin: 2px 0 0;
      .fs(16);
      display: block;
      .ff-light();
    }
    &:disabled {
      background-color: @gray;
      &:hover {
        background-color: @gray;
      }
    }
    &:hover {
      background-color: darken(@orange, 7%);
    }
  }
}

.where__button#place:after {
  content: '';
  background-image: url("img/town.png");
  position: relative;
  width: 100%;
}

/* NAŠEL JSEM */
.found {
  & h4 {
    line-height: 1;
    .ff-bold();
    clear: both;

    @media (max-width: @screen-is-max) {
      text-align: center;
      padding: 0 10px;
      .fs(20);
    }
    @media (min-width: @screen-xs) {
      .fs(24);
    }
  }
}

.found__notification {
  position: relative;
  overflow: auto;
  background-color: @blue-notification;
  @media (max-width: @screen-ts-max) {
    text-align: center;
  }
  p {
    .fs(14);
    margin: 0;
    position: relative;
    line-height: 1;
    float: left;
    @media (max-width: @screen-ts-max) {
      line-height: 1.2;
      text-align: left;
      padding: 19px 10px 8px 50px;
    }
    @media (min-width: @screen-sm) {
      padding: 19px 10px 19px 50px;

    }
  }
  &:before {
    content: '';
    position: absolute;
    background-image: data-uri('../img/found_notification.svg');
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    background-size: 25px;
    top: 50%;
    margin-top: -12.5px;
    left: 10px;
  }

  a {
    margin: 5px;
    background: @blue-button;
    color: @white;
    text-decoration: none;
    .fs(15);
    .ff-bold();
    border-radius: 3px;
    @media (max-width: @screen-ts-max) {
      clear: both;
      display: inline-block;
      padding: 8px 10px;
      margin-bottom: 15px;
    }
    @media (min-width: @screen-sm) {
      float: right;
      padding: 11px 12px;
    }

  }

}

.found__tab__selector__one {
  float: left;
  cursor: pointer;
  z-index: 10;
  & span {
    padding: 16px 52px;
    border-top: 1px solid #d4d4d4;
    border-right: 1px solid #d4d4d4;
    border-left: 1px solid #d4d4d4;
    border-bottom: 1px solid @light-blue;
    .fs(14);
    margin-top: 10px;
    line-height: 1;
    .ff-bold();
    display: block;
  }
  &.active {
    //padding-top: 15px;
    background-color: @light-blue;
    & span {
      border: 1px solid @light-blue;
    }
  }
}

.found__tab__inner {
  clear: both;
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.1);
  background: @light-blue;
  z-index: 20;
  @media (max-width: @screen-xs-max) {
    padding: 15px;
  }
  @media (min-width: @screen-ts) {
    padding: 30px;
  }
  & h5 {
    .fs(20);
    display: block;
    margin-top: 0;
    margin-bottom: 30px;
  }
  & h6 {
    .fs(17);
    .ff-bold();
    & span {
      .fs(24);
    }
  }
}

.found__tab__where,
.found__tab__inner__photo_wrapper {
  & input {
    height: 52px;
    border-radius: 4px;
    color: #9A9A9A;
    border: 1px solid #dbdbdb;
    width: 100%;
    padding: 10px 0 10px 14px;
    line-height: 1;
  }
}

.found__tab__inner__row {
  clear: both;
}

.found__tab__inner__photo_wrapper {
  float: left;
  width: 50%;
  position: relative;
  & p {
    text-align: center;
    color: @gray;
  }
}

.found__tab__inner__photo_wrapper__1 {
  @media (max-width: @screen-is-max) {
    width: 100%;
  }
  @media (min-width: @screen-xs) and (max-width: @screen-ts-max) {
    width: 52%;
  }
  @media (min-width: @screen-sm) {
    width: 63%;
  }

}

.found__tab__inner__photo_wrapper__2 {
  text-align: center;
  @media (max-width: @screen-is-max) {
    width: 100%;
  }
  @media (min-width: @screen-xs) and (max-width: @screen-ts-max) {
    width: 48%;
  }
  @media (min-width: @screen-sm) {
    width: 37%;
  }
  & label {
    background-color: @blue-button;
    .fs(15);
    .ff-bold();
    line-height: 1;
    position: relative;
    color: @white;
    border-radius: 3px;
    display: inline-block;
    cursor: pointer;
    transition-duration: 0.3s;
    &:hover {
      background-color: darken(@blue-button, 7%);
    }
    @media (max-width: @screen-is-max) {
      margin-top: 15px;
    }
    @media (max-width: @screen-xs-max) {
      padding: 17px 15px 17px 35px;
    }
    @media (min-width: @screen-ts) {
      padding: 17px 30px 17px 50px;
    }
    &:before {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      background-image: data-uri('../img/plus_white.svg');
      background-repeat: no-repeat;
      background-size: 15px;
      @media (max-width: @screen-xs-max) {
        left: 14px;
      }
      @media (min-width: @screen-ts) {
        left: 28px;
      }
    }
  }
}

.found__where__buttons {

  margin: 0 -4px;
}

.found__button__wrap {
  box-sizing: border-box;
  float: left;
  margin-top: 10px;
}

.found__button__wrap--1,
.found__button__wrap--2 {
  @media (max-width: @screen-is-max) {
    width: 100%;
  }
  @media (min-width: @screen-xs) and (max-width: @screen-md-max) {
    width: 50%;
  }
  @media (min-width: @screen-lg) {
    width: 25%;
  }
}

.found__button__wrap--4,
.found__button__wrap--5 {
  @media (max-width: @screen-is-max) {
    width: 100%;
  }
  @media (min-width: @screen-xs) and (max-width: @screen-md-max) {
    width: 50%;
  }
  @media (min-width: @screen-lg) {
    width: 35%;
  }
}

.found__button__wrap--3 {
  @media (max-width: @screen-md-max) {
    width: 100%;
  }
  @media (min-width: @screen-lg) {
    width: 50%;
  }
}

.found__button {
  margin: 0 4px;
  border: 1px solid @gray;
  border-radius: 5px;
  cursor: pointer;
  background-color: @white;
  @media (max-width: @screen-is-max) {
    display: block;
    margin: 0 0 5px;
  }
  & span {
    line-height: 1;
    display: block;
    position: relative;
    .ff-bold();
    @media (max-width: @screen-xs-max) {
      .fs(12);
    }
    @media (min-width: @screen-ts) {
      .fs(14);
    }
    &:before {
      content: '';
      position: absolute;
      top: 50%;

    }
  }
}

.found__button__3 {
  position: relative;
  margin-left: 5px;
  &:before {
    content: 'Upřesnění';
    top: -25px;
    left: 5px;
    position: absolute;

  }
  & input {
    width: 270px;
  }
}

.found__tab__radio {
  margin-bottom: 45px;
  padding-top: 15px;
  label {
    background-color: @white;
    color: @black;
    border-radius: 5px;
    cursor: pointer;
    transition-duration: 0.3s;
    line-height: 1;
    @media (max-width: @screen-is-max) {
      padding: 10px 10px;
      .fs(13);
    }
    @media (min-width: @screen-xs) {
      padding: 15px 20px;
    }
    @media (max-width: @screen-xs-max) {
      display: block;
      margin-top: 10px;
    }
    &.active {
      background-color: @orange;
      color: @white;
      transition-duration: 0.3s;
    }
  }
}

.found__tab__radio__one {
  @media (max-width: @screen-xs-max) {
    display: block;
  }
  @media (min-width: @screen-ts) {
    display: inline-block;
  }
}

.found__tab__inner__row__found_cat {
  overflow: auto;
  @media (max-width: @screen-is-max) {
    max-width: 200px;
    margin: 0 auto;
  }
  & h6 {
    margin-top: 20px;
  }
}

.found__tab__checkbox__row {
  box-sizing: border-box;
  display: flex;
  float: left;
  @media (max-width: @screen-is-max) {
    width: 100%;
  }
  @media (min-width: @screen-xs) and (max-width: @screen-md-max) {
    width: 50%;
  }
  @media (min-width: @screen-lg) {
    width: 33.33%;
  }
  label {
    .fs(13);
    width: 100%;
    line-height: 1;
    float: left;
    margin-bottom: 30px;
  }
}

.found__continue {
  @media (max-width: @screen-xs-max) {
    text-align: center;
  }
  @media (min-width: @screen-ts) {
    text-align: right;
  }
  & button {
    position: relative;
    display: inline-block;
    color: @white;
    padding: 19px 52px 19px 19px;
    border-radius: 4px;
    line-height: 1;
    .fs(17);
    background: @orange;
    text-decoration: none;
    &:disabled {
      background-color: @gray;
      &:hover {
        background-color: @gray;
      }
    }
    &:hover {
      background-color: darken(@orange, 7%);
    }
    &:after {
      content: '';
      position: absolute;
      background-image: data-uri('../img/arrow_next.svg');
      background-repeat: no-repeat;
      background-size: 18px 14px;
      width: 18px;
      height: 15px;
      right: 20px;
      top: 50%;
      margin-top: -7.5px;
    }
  }
}

.found__right {
  text-align: center;
  @media (max-width: @screen-sm-max) {
    margin-top: 32px;
  }
  @media (max-width: @screen-is-max) {
    text-align: center;
  }
  & img {
    margin-left: 45px;
    @media (max-width: @screen-is-max) {
      max-width: 200px;
    }
  }
  & h4 {
    position: relative;
    &:after {
      position: absolute;
      content: '';
      background-image: data-uri('../img/found_vratilka.svg');
      background-size: 65px 136px;
      width: 65px;
      height: 136px;
      right: 10px;
      top: -120px;
    }
  }
}

/* FOUND BOX 2 */

.found__box__code__headline {
  margin: 0;
}

.found__box__code__subhead {
  .fs(12);
}

.found__code {
  @media (max-width: @screen-is-max) {
    text-align: center;
    margin: 0 auto;
  }
  input {
    height: 54px;
    border-radius: 5px;
    padding-left: 15px;
    border: 1px solid @gray;
    @media (max-width: @screen-is-max) {
      width: 100%;
    }
    @media (min-width: @screen-xs) {
      float: left;
    }
    @media (min-width: @screen-xs) and (max-width: @screen-xs-max) {
      width: 150px;
    }
    @media (min-width: @screen-ts) {
      width: 250px;
    }
  }
  button {
    position: relative;
    background: @orange;
    color: @white;
    padding: 18px 52px 18px 19px;
    border-radius: 4px;
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
    .fs(17);
    text-decoration: none;
    @media (min-width: @screen-xs) {
      margin-left: 15px;
    }
    &:disabled {
      background-color: @gray;
      &:hover {
        background-color: @gray;
      }
    }
    &:hover {
      background-color: darken(@orange, 7%);
    }
    &:after {
      content: '';
      position: absolute;
      background-image: data-uri('../img/arrow_next.svg');
      background-repeat: no-repeat;
      background-size: 18px 14px;
      width: 18px;
      height: 15px;
      right: 20px;
      top: 50%;
      margin-top: -7.5px;
    }
  }
}

.found__code__continue {
  @media (max-width: @screen-is-max) {
    margin-top: 10px;
  }
}

.found__owner {
  clear: both;
  //margin-bottom: 30px;
  p {
    color: @green;
    text-align: center;
    .fs(24);
    display: block;
    padding-top: 10px;
  }
}

.found__owner__info__box {
  @media (max-width: @screen-xs-max) {
    text-align: center;
  }
  @media (min-width: @screen-ts) {
    display: inline-block;
    vertical-align: top;
  }
}

.found__owner__info__box--2 {
  @media (min-width: @screen-ts) {
    margin-left: 50px;
  }
}

.found__contact {
  clear: both;
  padding-top: 20px;
}

.found__contact__box {
  @media (min-width: @screen-ts) {
    float: left;
  }
}

.found__contact__box__subhead {
  .fs(12);
  padding-right: 10px;
}

.found__contact__box--1 {
  box-sizing: border-box;
  @media (max-width: @screen-xs-max) {
    width: 100%;
    text-align: center;
  }

  @media (min-width: @screen-ts) {
    width: 35%;
  }

}

.found__contact__box--2 {
  @media (max-width: @screen-sm-max) {
    margin: 0 auto;
  }
  @media (min-width: @screen-ts) {
    width: 65%;
    box-sizing: border-box;
  }
}

.found__contact__box__row {
  p {
    box-sizing: border-box;
    margin: 0;
    @media (max-width: @screen-is-max) {
      padding: 0;
    }
    @media (min-width: @screen-xs) {
      float: left;
      width: 20%;
      padding: 13px 0;
    }
  }
  input {
    height: 52px;
    border-radius: 4px;
    border: 1px solid @gray;
    margin-bottom: 10px;
    width: 100%;
    padding-left: 15px;
  }
}

.found__contact__box__wrap {
  box-sizing: border-box;
  @media (min-width: @screen-xs) {
    float: left;
    width: 80%;
  }
  a {
    position: relative;
    background: #f37e33;
    color: #ffffff;
    float: right;
    padding: 17px 19px 17px 19px;
    border-radius: 4px;
    line-height: 1;
    .fs(17);
    text-decoration: none;
  }
}

/*
  THANK YOU PAGE
 */
.found__tab__thankyou {
  text-align: center;
  span {
    color: @green;
    .ff-bold();
  }
  h5 {
    .fs(25);
    margin-top: 40px;
  }
  p {

    max-width: 550px;
    display: block;
    margin: 0 auto;
  }
}

.found__tab__thankyou__headline {
  .fs(36);
  .ff-bold();
  text-align: center;
}

.found__tab__thankyou__subhead {
  p {
    .fs(18);
  }
}

.found__tab__email__left {
  span {

    margin: 15px 0;
    display: block;
    @media (max-width: @screen-xs-max) {
      text-align: center;
    }
    @media (min-width: @screen-ts) {
      text-align: right;
    }
  }
}

.found__tab__email__left__anonym {
  .ff-bold();
}

.found__tab__email__right {
  @media (max-width: @screen-xs-max) {
    text-align: center;
  }
  input {
    height: 52px;
    border: 1px solid @gray;
    border-radius: 4px;
    @media (max-width: @screen-xs-max) {
      width: 100%;
    }
    @media (min-width: @screen-ts) {
      width: 70%;
    }
  }
}

.found__tab__email__cred {
  @media (max-width: @screen-xs-max) {
    text-align: center;
  }
}

.found__tab__emaiL__first {
  @media (min-width: @screen-ts) {
    padding-bottom: 70px;
  }
}

.found__tab__emaiL__second {
  @media (min-width: @screen-ts) {
    padding-bottom: 30px;
  }
}

.found__tab__email__actions {
  clear: both;
}

.found__tab__email__actions {
  @media (max-width: @screen-xs-max) {
    padding-top: 30px;
    text-align: center;
  }
  div {
    @media (max-width: @screen-xs-max) {
      padding-top: 15px;
    }
    @media (min-width: @screen-ts) {
      float: right;
    }
    a {
      margin: 0;
    }
  }
}

.found__tab__email__previous {
  margin: 15px 0;
  padding-left: 22px;
  color: @blue-button;
  text-decoration: underline;
  cursor: pointer;
  transition-duration: 0.3s;
  position: relative;
  @media (min-width: @screen-ts) {
    float: left;
  }
  &:hover {
    color: @orange;
    &:before {
      opacity: 0;
    }
    &:after {
      opacity: 1;
    }
  }
  &:before {
    content: '';
    position: absolute;
    opacity: 1;
    background: data-uri('../img/arrow_next_blue.svg') center center no-repeat;
    background-size: 18px 14px;
    width: 18px;
    height: 14px;
    top: 50%;
    margin-top: -7px;
    left: 0;
    transition-duration: 0.3s;
  }
  &:after {
    content: '';
    position: absolute;
    opacity: 0;
    background: data-uri('../img/arrow_next_orange.svg') center center no-repeat;
    background-size: 18px 14px;
    width: 18px;
    height: 14px;
    top: 50%;
    margin-top: -7px;
    left: 0;
    transition-duration: 0.3s;
  }
}

.found__tab__email__foot {
  clear: both;
  @media (max-width: @screen-xs-max) {
    padding-top: 40px;

  }
}

/* TOTO JE VRÁTILKA */
.return {
  background-color: @dark-gray;
  color: @white;
  padding-bottom: 30px;
  & h1 {
    .fs(36);
    margin-top: 70px;
    .ff-bold();
    line-height: 1;
    margin-bottom: 30px;
  }
  & h2 {
    .fs(19);
    line-height: 1;
    margin-top: 0;
    .ff-bold();
    margin-bottom: 5px;
  }
  & p {
    .fs(19);
    .ff-normal();
  }
}

.return .image {
  margin-top: 40px;
}

.return__right {
  display: block;
  margin-top: 100px;
  & p {
    line-height: 1.3;
    .fs(23);
    .ff-light();
  }
  & span {
    line-height: 1;
    .fs(48);
    .ff-bold();
    color: @orange;
  }
}

#secure {
  background: url('../img/secure_bg.png') center center;
  overflow: hidden;
  @media (max-width: @screen-is-max) {
    padding: 32px 0 0 0;
  }
  @media (min-width: @screen-xs) {
    padding: 32px 32px 0 32px;
  }
  @media (max-width: @screen-md-max) {
    padding-bottom: 32px;
  }
  & h1 {
    .fs(26);
    text-align: center;
    .ff-bold();
  }
  & .image__wrapper {
    overflow: hidden;
    text-align: center;
    margin-top: 30px;
    @media (min-width: @screen-lg) {
      float: left;
      width: 60%;
    }
    img {
      @media (max-width: @screen-md-max) {
        max-height: 490px;
        max-width: 100%;
      }
      @media (min-width: @screen-lg) {
        max-width: 550px;
        max-height: 490px;
      }

    }
  }
  & .list__wrapper {
    @media (min-width: @screen-lg) {
      float: left;
      width: 40%;
    }
    margin-top: 80px;
    @media (max-width: @screen-md-max) {
      text-align: center;
    }
    & ul {
      list-style: none;
      padding: 0;
      @media (min-width: @screen-lg) {
        float: right;
      }
      & li {
        .fs(18);
        .ff-bold();
        width: 372px;
        padding: 12px;
        background-color: @white;
        color: #1d99ce;
        text-decoration: underline;
        margin-bottom: 1px;
        cursor: pointer;
        position: relative;
        @media (max-width: @screen-md-max) {
          .fs(12);
          display: inline;
        }
        @media (max-width: @screen-sm-max) {
          &:nth-of-type(2n+1) {
            display: none;
          }
        }
        &.active {
          color: @black;
          text-decoration: none;
          box-shadow: 0 0 16px @black-3;
          @media (max-width: @screen-md-max) {
            padding: 16px;
            display: inline;
            box-shadow: none;
          }
        }
        &.active:before {
          content: '';
          position: absolute;
          margin-top: -12px;
          margin-left: -39px;
          background: transparent;
          border-top: 27px solid transparent;
          border-bottom: 27px solid transparent;
          border-right: 27px solid @white;
          @media (max-width: @screen-md-max) {
            top: -31px;
            left: 50%;
            margin-left: -8px;
            border-left: 16px solid transparent;
            border-bottom: 16px solid @white;
            border-right: 16px solid transparent;
          }
        }
      }
    }
    & button {
      width: 49%;
      @media (max-width: @screen-md-max) {
        width: 250px;
        margin-top: 24px;
      }
      &.button__activate {
        background-color: @orange;
      }
      &.button__buy {
        background-color: @green;
      }
    }
  }
}

#sponsors {
  text-align: center;
  & img {
    margin: 16px 8px;
  }
}

#projects {
  background-color: #424347;
  color: @white;
  text-align: center;
  padding-bottom: 52px;
  & h1 {
    .fs(32);
    .ff-light();
    margin: 32px 0;
  }
  & a {
    background: transparent;
    border: 1px solid @gray;
    padding: 14px 42px 14px 21px;
    border-radius: 3px;
    position: relative;
    text-decoration: none;
    color: @white;
  }
  & a:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 6px;
    border-color: transparent transparent transparent #ffffff;
    position: absolute;
    top: 20px;
    display: block;
    right: 18px;
    z-index: 1;
  }
  & a:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 4px;
    border-color: transparent transparent transparent #424347;
    position: absolute;
    top: 22px;
    right: 20px;
    z-index: 2;
  }
  & .project__wrap {
    padding: 9px;
    display: inline-block;
    width: 33%;
    box-sizing: border-box;
    @media (max-width: @screen-ts-max) {
      width: 100%;
    }
    @media (min-width: @screen-ts) and (max-width: @screen-sm-max) {

    }
    & .project__wrap__inner {
      border: 1px solid @gray;
      border-radius: 5px;
      padding: 28px 48px;
      & .img__wrap {
        height: 88px;
      }
      & p {
        .ff-light();
        margin: 24px 0;
      }
    }
  }
}

.lost__what {
  clear: both;
}
