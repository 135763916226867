.left {
  float: left;
}
.right {
  float: right;
}
.block {
  display: block;
}
.none {
  display: none;
}
.inline-block {
  display: inline-block;
  vertical-align: middle;
}
.inline {
  display: inline;
}
.center {
  margin-left: auto;
  margin-right: auto;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.static {
  position: static;
}
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.row:before,
.row:after {
  content: " ";
  display: table;
}
.clearfix:after,
.container:after,
.row:after {
  clear: both;
}
.cleaner {
  clear: both;
}
.cleaner-left {
  clear: left;
}
.cleaner-right {
  clear: right;
}
.strong {
  font-weight: 700;
}
.italic {
  font-style: italic;
}
.text-justify {
  text-align: justify;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-uppercase {
  text-transform: uppercase;
}
.u--left {
  float: left !important;
}
.u--right {
  float: right !important;
}
.u--block {
  display: block !important;
}
.u--none {
  display: none !important;
}
.u--inline-block {
  display: inline-block !important;
}
.u--inline {
  display: inline !important;
}
.u--center {
  margin-left: auto !important;
  margin-right: auto !important;
}
.u--relative {
  position: relative !important;
}
.u--absolute {
  position: absolute !important;
}
.u--static {
  position: static !important;
}
.u--cleaner {
  clear: both !important;
}
.u--cleaner-left {
  clear: left !important;
}
.u--cleaner-right {
  clear: right !important;
}
.u--strong {
  font-weight: 700 !important;
}
.u--italic {
  font-style: italic !important;
}
.u--text-justify {
  text-align: justify !important;
}
.u--text-left {
  text-align: left !important;
}
.u--text-right {
  text-align: right !important;
}
.u--text-center {
  text-align: center !important;
}
.u--text-uppercase {
  text-transform: uppercase !important;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden] {
  display: none;
}
html {
  font-family: 'Open Sans', sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  margin: 0;
}
a:focus {
  outline: thin dotted;
}
a:active,
a:hover {
  outline: 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
mark {
  background: #ff0;
  color: #000;
}
code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}
q {
  quotes: "\201C" "\201D" "\2018" "\2019";
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 0;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}
button,
input {
  line-height: normal;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
input[disabled] {
  cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  max-width: 1200px;
}
.row {
  margin-left: -15px;
  margin-right: -15px;
}
.col--is--1, .col--xs--1, .col--ts--1, .col--sm--1, .col--md--1, .col--lg--1, .col--is--2, .col--xs--2, .col--ts--2, .col--sm--2, .col--md--2, .col--lg--2, .col--is--3, .col--xs--3, .col--ts--3, .col--sm--3, .col--md--3, .col--lg--3, .col--is--4, .col--xs--4, .col--ts--4, .col--sm--4, .col--md--4, .col--lg--4, .col--is--5, .col--xs--5, .col--ts--5, .col--sm--5, .col--md--5, .col--lg--5, .col--is--6, .col--xs--6, .col--ts--6, .col--sm--6, .col--md--6, .col--lg--6, .col--is--7, .col--xs--7, .col--ts--7, .col--sm--7, .col--md--7, .col--lg--7, .col--is--8, .col--xs--8, .col--ts--8, .col--sm--8, .col--md--8, .col--lg--8, .col--is--9, .col--xs--9, .col--ts--9, .col--sm--9, .col--md--9, .col--lg--9, .col--is--10, .col--xs--10, .col--ts--10, .col--sm--10, .col--md--10, .col--lg--10, .col--is--11, .col--xs--11, .col--ts--11, .col--sm--11, .col--md--11, .col--lg--11, .col--is--12, .col--xs--12, .col--ts--12, .col--sm--12, .col--md--12, .col--lg--12 {
  box-sizing: border-box;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col--is--1, .col--is--2, .col--is--3, .col--is--4, .col--is--5, .col--is--6, .col--is--7, .col--is--8, .col--is--9, .col--is--10, .col--is--11, .col--is--12 {
  float: left;
}
.col--is--12 {
  width: 100%;
}
.col--is--11 {
  width: 91.66666667%;
}
.col--is--10 {
  width: 83.33333333%;
}
.col--is--9 {
  width: 75%;
}
.col--is--8 {
  width: 66.66666667%;
}
.col--is--7 {
  width: 58.33333333%;
}
.col--is--6 {
  width: 50%;
}
.col--is--5 {
  width: 41.66666667%;
}
.col--is--4 {
  width: 33.33333333%;
}
.col--is--3 {
  width: 25%;
}
.col--is--2 {
  width: 16.66666667%;
}
.col--is--1 {
  width: 8.33333333%;
}
@media (min-width: 480px) {
  .col--xs--1, .col--xs--2, .col--xs--3, .col--xs--4, .col--xs--5, .col--xs--6, .col--xs--7, .col--xs--8, .col--xs--9, .col--xs--10, .col--xs--11, .col--xs--12 {
    float: left;
  }
  .col--xs--12 {
    width: 100%;
  }
  .col--xs--11 {
    width: 91.66666667%;
  }
  .col--xs--10 {
    width: 83.33333333%;
  }
  .col--xs--9 {
    width: 75%;
  }
  .col--xs--8 {
    width: 66.66666667%;
  }
  .col--xs--7 {
    width: 58.33333333%;
  }
  .col--xs--6 {
    width: 50%;
  }
  .col--xs--5 {
    width: 41.66666667%;
  }
  .col--xs--4 {
    width: 33.33333333%;
  }
  .col--xs--3 {
    width: 25%;
  }
  .col--xs--2 {
    width: 16.66666667%;
  }
  .col--xs--1 {
    width: 8.33333333%;
  }
}
@media (min-width: 580px) {
  .col--ts--1, .col--ts--2, .col--ts--3, .col--ts--4, .col--ts--5, .col--ts--6, .col--ts--7, .col--ts--8, .col--ts--9, .col--ts--10, .col--ts--11, .col--ts--12 {
    float: left;
  }
  .col--ts--12 {
    width: 100%;
  }
  .col--ts--11 {
    width: 91.66666667%;
  }
  .col--ts--10 {
    width: 83.33333333%;
  }
  .col--ts--9 {
    width: 75%;
  }
  .col--ts--8 {
    width: 66.66666667%;
  }
  .col--ts--7 {
    width: 58.33333333%;
  }
  .col--ts--6 {
    width: 50%;
  }
  .col--ts--5 {
    width: 41.66666667%;
  }
  .col--ts--4 {
    width: 33.33333333%;
  }
  .col--ts--3 {
    width: 25%;
  }
  .col--ts--2 {
    width: 16.66666667%;
  }
  .col--ts--1 {
    width: 8.33333333%;
  }
}
@media (min-width: 768px) {
  .col--sm--1, .col--sm--2, .col--sm--3, .col--sm--4, .col--sm--5, .col--sm--6, .col--sm--7, .col--sm--8, .col--sm--9, .col--sm--10, .col--sm--11, .col--sm--12 {
    float: left;
  }
  .col--sm--12 {
    width: 100%;
  }
  .col--sm--11 {
    width: 91.66666667%;
  }
  .col--sm--10 {
    width: 83.33333333%;
  }
  .col--sm--9 {
    width: 75%;
  }
  .col--sm--8 {
    width: 66.66666667%;
  }
  .col--sm--7 {
    width: 58.33333333%;
  }
  .col--sm--6 {
    width: 50%;
  }
  .col--sm--5 {
    width: 41.66666667%;
  }
  .col--sm--4 {
    width: 33.33333333%;
  }
  .col--sm--3 {
    width: 25%;
  }
  .col--sm--2 {
    width: 16.66666667%;
  }
  .col--sm--1 {
    width: 8.33333333%;
  }
}
@media (min-width: 992px) {
  .col--md--1, .col--md--2, .col--md--3, .col--md--4, .col--md--5, .col--md--6, .col--md--7, .col--md--8, .col--md--9, .col--md--10, .col--md--11, .col--md--12 {
    float: left;
  }
  .col--md--12 {
    width: 100%;
  }
  .col--md--11 {
    width: 91.66666667%;
  }
  .col--md--10 {
    width: 83.33333333%;
  }
  .col--md--9 {
    width: 75%;
  }
  .col--md--8 {
    width: 66.66666667%;
  }
  .col--md--7 {
    width: 58.33333333%;
  }
  .col--md--6 {
    width: 50%;
  }
  .col--md--5 {
    width: 41.66666667%;
  }
  .col--md--4 {
    width: 33.33333333%;
  }
  .col--md--3 {
    width: 25%;
  }
  .col--md--2 {
    width: 16.66666667%;
  }
  .col--md--1 {
    width: 8.33333333%;
  }
}
@media (min-width: 1170px) {
  .col--lg--1, .col--lg--2, .col--lg--3, .col--lg--4, .col--lg--5, .col--lg--6, .col--lg--7, .col--lg--8, .col--lg--9, .col--lg--10, .col--lg--11, .col--lg--12 {
    float: left;
  }
  .col--lg--12 {
    width: 100%;
  }
  .col--lg--11 {
    width: 91.66666667%;
  }
  .col--lg--10 {
    width: 83.33333333%;
  }
  .col--lg--9 {
    width: 75%;
  }
  .col--lg--8 {
    width: 66.66666667%;
  }
  .col--lg--7 {
    width: 58.33333333%;
  }
  .col--lg--6 {
    width: 50%;
  }
  .col--lg--5 {
    width: 41.66666667%;
  }
  .col--lg--4 {
    width: 33.33333333%;
  }
  .col--lg--3 {
    width: 25%;
  }
  .col--lg--2 {
    width: 16.66666667%;
  }
  .col--lg--1 {
    width: 8.33333333%;
  }
}
/*
input[type="radio"] {
  display: none;
}

input[type=radio]:checked + label:after {
  content: '';
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: @orange;
  font-size: 40px;
  text-align: center;
  line-height: 18px;
  @media (max-width: @screen-is-max) {
    left: 15px;
    top: 15px;
  }
  @media (min-width: @screen-xs) {
    left: 25px;
    top: 20px;
  }
}
*/
.radio,
.checkbox {
  opacity: 0;
  position: absolute;
}
.checkbox,
.checkbox__label,
.radio,
.radio__label {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  cursor: pointer;
}
.checkbox__label,
.radio__label {
  position: relative;
}
.checkbox + .checkbox__label:before,
.radio + .radio__label:before {
  content: '';
  background: #fff;
  border: 1px solid #ccc;
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 2px;
  margin-right: 10px;
  text-align: center;
  margin-bottom: -5.5px;
}
.checkbox:checked + .checkbox__label:before {
  background: #f37e33;
  box-shadow: inset 0px 0px 0px 4px #fff;
}
.radio + .radio__label:before {
  border-radius: 50%;
}
.radio:checked + .radio__label:before {
  background: #f37e33;
  box-shadow: inset 0px 0px 0px 4px #fff;
}
.checkbox:focus + .checkbox__label,
.radio:focus + .radio__label {
  outline: 1px solid #ddd;
  /* focus style */
}
.input__file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.input__error {
  clear: both;
}
.input__error p {
  width: 100%;
  display: block;
  position: relative;
  margin-top: 10px;
  border-radius: 3px;
  background-color: #ffc2c4;
  padding: 10px 0;
  color: #c1393d !important;
  font-weight: 700;
  line-height: 1.3;
  font-size: 12px;
  font-size: 0.75rem;
}
.input__error p span {
  display: block;
  margin: 0 40px;
  text-align: left;
}
.input__error p:before {
  content: '';
  position: absolute;
  background: url('../img/input_error.svg') center center no-repeat;
  left: 10px;
  top: 50%;
  margin-top: -8px;
  background-size: 16px;
  width: 16px;
  height: 16px;
}
img {
  max-width: 100%;
  height: auto;
}
button,
.button {
  transition-duration: 0.3s;
  display: inline-block;
  text-align: center;
  border-width: 0;
  padding: 18px 24px;
  text-decoration: none;
  font-weight: normal;
  color: #ffffff;
  cursor: pointer;
  background-color: #000000;
  border-radius: 5px;
}
button:hover,
.button:hover {
  text-decoration: none;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.8);
}
.button--orange {
  background-color: #f37e33;
}
.button--orange:hover {
  background-color: #f16911;
}
.button--green {
  background-color: #84c011;
}
.button--green:hover {
  background-color: #6d9f0e;
}
a {
  cursor: pointer;
}
p {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.7;
  margin: 0 0 1em;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 1em;
  margin-left: 0;
  margin-right: 0;
  line-height: 1.15;
  font-weight: 400;
}
h1:first-child,
.h1:first-child,
h2:first-child,
.h2:first-child,
h3:first-child,
.h3:first-child,
h4:first-child,
.h4:first-child,
h5:first-child,
.h5:first-child,
h6:first-child,
.h6:first-child {
  margin-top: 0;
}
h1,
.h1 {
  font-size: 43px;
  font-size: 2.6875rem;
  margin-bottom: 0.37209302325581395em;
}
h2,
.h2 {
  font-size: 35px;
  font-size: 2.1875rem;
  margin-bottom: 0.45714285714285713em;
}
h3,
.h3 {
  font-size: 27px;
  font-size: 1.6875rem;
  margin-bottom: 0.5925925925925926em;
}
h4,
.h4 {
  font-size: 23px;
  font-size: 1.4375rem;
  margin-bottom: 0.6956521739130435em;
}
h5,
.h5 {
  font-size: 19px;
  font-size: 1.1875rem;
  margin-bottom: 0.8421052631578947em;
}
h6,
.h6 {
  font-size: 17px;
  font-size: 1.0625rem;
  margin-bottom: 0.9411764705882353em;
}
ul {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 1em;
  padding: 0;
}
ul li {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.7;
  padding: 0 0 0 16px;
  color: #7f7f7e;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0.5333333333333333em;
}
ul ol,
ul ul {
  margin-top: 0.5333333333333333em;
  margin-bottom: 0;
}
ul ol li {
  background: none;
}
ol {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 1em;
  padding: 0 0 0 11px;
}
ol li {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.7;
  padding: 0 0 0 5px;
  color: #7f7f7e;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0.5333333333333333em;
}
ol ol,
ol ul {
  margin-top: 0.5333333333333333em;
  margin-bottom: 0;
}
ol ul li {
  padding-left: 16px;
}
blockquote {
  margin: 0;
  padding-left: 68px;
}
blockquote,
blockquote p {
  font-size: 15px;
  font-size: 0.9375rem;
  font-style: italic;
  text-align: left;
  line-height: 1.6;
}
.button__active.active {
  border: 1px solid #f37e33;
  box-shadow: inset 0px 0px 0 1px #f37e33;
}
@media (min-width: 480px) {
  .top__bar {
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
  }
}
@media (max-width: 479px) {
  .top__bar__items {
    margin: 0 auto;
    max-width: 314px;
  }
}
@media (min-width: 480px) {
  .top__bar__items {
    float: right;
  }
}
.top__bar__items__item {
  font-size: 14px;
  font-size: 0.875rem;
  float: left;
}
@media (min-width: 480px) {
  .top__bar__items__item {
    border-left: 1px solid #ccc;
  }
}
.top__bar__items__item__1 span {
  display: block;
  cursor: pointer;
  position: relative;
}
@media (max-width: 479px) {
  .top__bar__items__item__1 span {
    padding: 9px 8px 8px 8px;
  }
}
@media (min-width: 480px) {
  .top__bar__items__item__1 span {
    padding: 9px 35px 8px 15px;
  }
}
.top__bar__items__item__1 span:after {
  content: '';
  position: absolute;
  background: url('../img/login_dropdown.svg') center center no-repeat;
  width: 9px;
  height: 5px;
  top: 50%;
  margin-top: -2.5px;
  right: 15px;
}
@media (max-width: 479px) {
  .top__bar__items__item__1 span:after {
    display: none;
  }
}
.top__bar__items__item__1 span img {
  line-height: 1;
}
.top__bar__items__item__2 a {
  padding: 9px 12px;
  color: #84c011;
  display: block;
  font-weight: 700;
  line-height: 1;
}
@media (max-width: 479px) {
  .top__bar__items__item__2 a {
    border-left: 1px solid #ccc;
  }
}
.top__bar__items__item__3 {
  border-left: 1px solid #ccc;
}
@media (min-width: 480px) {
  .top__bar__items__item__3 {
    border-right: 1px solid #ccc;
  }
}
.top__bar__items__item__3 span {
  text-decoration: underline;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1;
  padding: 10px 30px 9px 35px;
  display: block;
  position: relative;
  cursor: pointer;
}
.top__bar__items__item__3 span:before {
  content: '';
  position: absolute;
  background: url('../img/login_user.svg') center center no-repeat;
  width: 11px;
  height: 12px;
  background-size: 11px 12px;
  left: 15px;
  top: 50%;
  margin-top: -6px;
}
.top__bar__items__item__3 span:after {
  content: '';
  position: absolute;
  background: url('../img/login_dropdown.svg') center center no-repeat;
  width: 9px;
  height: 5px;
  right: 15px;
  top: 50%;
  margin-top: -2.5px;
}
.header__bar {
  clear: both;
  background-color: #ffffff;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  overflow: auto;
}
.header__bar__logo__corp {
  float: left;
  width: 30%;
  position: relative;
  height: 94px;
}
.header__bar__logo__corp img {
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-height: 88px;
}
@media (max-width: 767px) {
  .header__bar__logo__corp {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .header__bar__logo__corp {
    width: 30%;
  }
}
.header__bar__logo__headline {
  font-size: 12px;
  font-size: 0.75rem;
  margin-bottom: 5px;
  display: block;
}
.header__bar__logo {
  margin: 14px 0;
}
@media (max-width: 767px) {
  .header__bar__logo {
    text-align: center;
  }
}
.header__bar__logo.corp {
  float: left;
  margin: 21px 0;
}
.header__bar__logo.corp img {
  max-width: 118px;
}
@media (max-width: 767px) {
  .header__bar__logo {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .header__bar__logo {
    padding-left: 20px;
  }
}
.header__bar__middle {
  text-align: center;
}
.header__bar__middle h1 {
  font-size: 23px;
  font-size: 1.4375rem;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 10px;
}
.header__bar__middle h2 {
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 25px;
  margin-top: 0;
}
@media (max-width: 479px) {
  .header__bar__middle {
    display: none;
  }
}
@media (max-width: 767px) {
  .header__bar__corp {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .header__bar__corp {
    text-align: right;
  }
}
.header__bar__corp a {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px 28px 15px 58px;
  margin: 22px 0;
  display: inline-block;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1;
  color: black;
  text-decoration: none;
  font-weight: 700;
  position: relative;
}
.header__bar__corp a:before {
  content: '';
  position: absolute;
  background: url('../img/corp_login.png') center center no-repeat;
  width: 20px;
  height: 22px;
  left: 25px;
  background-size: 20px 22px;
  top: 50%;
  margin-top: -11px;
}
.header__bar__corp a.corp:before {
  background: url('../img/go_to.svg') center center no-repeat;
}
@media (max-width: 767px) {
  .tabs__background > .container {
    padding: 0;
  }
}
.tab__box {
  position: relative;
  padding-top: 32px;
  padding-bottom: 64px;
}
.tab__box:before {
  content: '';
  position: absolute;
  background-image: url('../img/hp_wallet.png');
  background-size: 234.57142857px 128px;
  left: -234.57142857px;
  background-repeat: no-repeat;
  top: 70%;
  right: 0;
  width: 234.57142857px;
  height: 128px;
}
@media (max-width: 1169px) {
  .tab__box:before {
    display: none;
  }
}
.tab__box:after {
  content: '';
  position: absolute;
  background-image: url('../img/hp_umbrella.png');
  background-size: 145.5px 552.5px;
  right: -145.5px;
  background-repeat: no-repeat;
  top: 15%;
  width: 145.5px;
  height: 552.5px;
}
@media (max-width: 1169px) {
  .tab__box:after {
    display: none;
  }
}
.tabs__background {
  overflow: hidden;
  background-image: url('../img/tabs_background.png');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  position: relative;
  padding-bottom: 64px;
}
.tab__box__wrapper {
  display: flex;
}
.tab__box__wrapper__one {
  float: left;
  margin-top: 15px;
  display: inline-flex;
  text-transform: uppercase;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  vertical-align: bottom;
  position: relative;
}
@media (max-width: 579px) {
  .tab__box__wrapper__one {
    width: 50%;
  }
}
@media (min-width: 580px) {
  .tab__box__wrapper__one {
    width: 33.33%;
  }
}
.tab__box__wrapper__one h3 {
  font-weight: 700;
  cursor: pointer;
  line-height: 1;
  text-decoration: underline;
  margin: 0;
  position: relative;
  transition-duration: 0.3s;
}
@media (max-width: 479px) {
  .tab__box__wrapper__one h3 {
    font-size: 14px;
    font-size: 0.875rem;
    padding: 20px 0 20px 50px;
  }
}
@media (min-width: 480px) {
  .tab__box__wrapper__one h3 {
    padding: 24px 0 24px 78px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .tab__box__wrapper__one h3 {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}
@media (min-width: 768px) {
  .tab__box__wrapper__one h3 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.tab__box__wrapper__one h3 span {
  color: #595959;
  font-weight: 400;
  text-transform: none;
  padding-top: 7px;
  padding-right: 5px;
  text-decoration: none;
  line-height: 1.2;
  display: inline-block;
}
@media (max-width: 479px) {
  .tab__box__wrapper__one h3 span {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .tab__box__wrapper__one h3 span {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media (min-width: 768px) {
  .tab__box__wrapper__one h3 span {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.tab__box__wrapper__one h3:before {
  content: '';
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -20px;
  width: 40px;
  height: 40px;
}
@media (max-width: 479px) {
  .tab__box__wrapper__one h3:before {
    left: 10px;
    margin-top: -15px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 480px) {
  .tab__box__wrapper__one h3:before {
    left: 20px;
    margin-top: -20px;
    width: 40px;
    height: 40px;
  }
}
.tab__box__wrapper__one.active {
  padding-top: 15px;
  margin: 0;
  z-index: 20;
}
.tab__box__wrapper__one.active h3:after {
  content: '';
  position: absolute;
  height: 10px;
  background-color: #ffffff;
  width: 100%;
  bottom: -10px;
  left: 0;
  z-index: 20;
}
.tab__box__wrapper__one__1 h3:hover {
  text-decoration: none;
  color: #1d99ce;
}
.tab__box__wrapper__one__1 h3:before {
  background-image: url('../img/tab1.png');
  background-repeat: no-repeat;
  z-index: 1;
}
@media (max-width: 479px) {
  .tab__box__wrapper__one__1 h3:before {
    background-size: 30px;
  }
}
@media (min-width: 480px) {
  .tab__box__wrapper__one__1 h3:before {
    background-size: 40px;
  }
}
.tab__box__wrapper__one__1.active h3 {
  text-decoration: none;
  color: #1d99ce;
}
.tab__box__wrapper__one__2 {
  z-index: 2;
}
.tab__box__wrapper__one__2 h3:hover {
  text-decoration: none;
  color: #e15c61;
}
.tab__box__wrapper__one__2 h3:before {
  background-image: url('../img/tab2.png');
  background-repeat: no-repeat;
  z-index: 1;
}
@media (max-width: 479px) {
  .tab__box__wrapper__one__2 h3:before {
    background-size: 30px;
  }
}
@media (min-width: 480px) {
  .tab__box__wrapper__one__2 h3:before {
    background-size: 40px;
  }
}
.tab__box__wrapper__one__2.active h3 {
  text-decoration: none;
  color: #e15c61;
}
@media (max-width: 579px) {
  .tab__box__wrapper__one__3 {
    display: none;
  }
}
.tab__box__wrapper__one__3 h3:hover {
  text-decoration: none;
  color: #84c011;
}
.tab__box__wrapper__one__3 h3:before {
  background-image: url('../img/tab3.png');
  background-size: 40px;
  background-repeat: no-repeat;
}
@media (max-width: 479px) {
  #found.tab__box__holder {
    padding: 20px 0;
  }
}
@media (min-width: 480px) {
  #found.tab__box__holder {
    padding: 20px 25px;
  }
}
.tab__box__holder {
  clear: both;
  background-color: #ffffff;
  box-shadow: 0 -3px 16px #aaa;
  z-index: 10;
  position: relative;
}
#tab__box #content #cooperation {
  background-color: #e7f4f9;
  clear: both;
  margin: 0;
  text-align: center;
}
#tab__box #content #cooperation p {
  padding-top: 16px;
}
/*
#tab__box #content #cooperation img {
    margin: 32px 16px;
}
*/
@media (max-width: 479px) {
  .partners__wrapper__text {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
.partners__wrapper__outer {
  padding: 10px 10px 32px;
}
@media (max-width: 579px) {
  .partners__wrapper {
    text-align: center;
  }
  .partners__wrapper:before,
  .partners__wrapper:after {
    content: " ";
    display: table;
  }
  .partners__wrapper:after {
    clear: both;
  }
  .partners__wrapper:before,
  .partners__wrapper:after {
    content: " ";
    display: table;
  }
  .partners__wrapper:after {
    clear: both;
  }
}
@media (min-width: 580px) {
  .partners__wrapper {
    display: table;
    width: 100%;
  }
}
@media (max-width: 479px) {
  .partners__wrapper__cell {
    width: 50%;
  }
}
@media (min-width: 480px) and (max-width: 579px) {
  .partners__wrapper__cell {
    width: 33%;
  }
}
@media (max-width: 579px) {
  .partners__wrapper__cell {
    box-sizing: border-box;
    float: left;
    padding: 10px;
    height: 80px;
  }
}
@media (min-width: 580px) {
  .partners__wrapper__cell {
    display: table-cell;
    padding: 0 10px;
  }
}
.lost__items__wrapper:before,
.lost__items__wrapper:after {
  content: " ";
  display: table;
}
.lost__items__wrapper:after {
  clear: both;
}
.lost__items__wrapper:before,
.lost__items__wrapper:after {
  content: " ";
  display: table;
}
.lost__items__wrapper:after {
  clear: both;
}
@media (max-width: 479px) {
  .lost__items__wrapper {
    margin: 0 -4px;
  }
}
@media (min-width: 480px) {
  .lost__items__wrapper {
    margin: 0 -8px;
  }
}
.lost__what__wrapper {
  position: relative;
}
.lost__what__wrapper h4 {
  font-size: 24px;
  font-size: 1.5rem;
}
.lost__what__wrapper h4 span {
  font-weight: 700;
}
@media (max-width: 479px) {
  .lost__what__wrapper {
    padding: 10px 15px 20px;
  }
}
@media (min-width: 480px) {
  .lost__what__wrapper {
    padding: 20px 30px;
  }
}
.lost__what__wrapper__headline {
  margin-top: 0;
}
.lost__item {
  float: left;
  box-sizing: border-box;
}
@media (min-width: 480px) {
  .lost__item {
    padding: 8px;
  }
}
@media (max-width: 479px) {
  .lost__item {
    padding: 4px;
    width: 33.333%;
  }
}
@media (min-width: 480px) and (max-width: 579px) {
  .lost__item {
    width: 25%;
  }
}
@media (min-width: 580px) and (max-width: 991px) {
  .lost__item {
    width: 25%;
  }
}
@media (min-width: 992px) {
  .lost__item {
    width: 14.28%;
  }
}
@media (max-width: 479px) {
  .lost__item:nth-of-type(n+4) {
    width: 25%;
  }
}
.lost__item__inner {
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  padding: 8px;
  height: 120px;
  text-align: center;
  background-color: #ffffff;
}
@media (max-width: 479px) {
  .lost__item__inner {
    font-size: 10px;
    font-size: 0.625rem;
  }
}
@media (min-width: 480px) {
  .lost__item__inner {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.lost__item__inner span {
  display: block;
  padding-top: 7px;
}
.lost__item__inner.active {
  border: 1px solid #f37e33;
  box-shadow: inset 0px 0px 0 1px #f37e33;
}
.lost__item__img__wrap {
  height: 68px;
  position: relative;
}
.lost__item img {
  display: block;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-height: 55px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .where__section {
    max-width: 690px;
  }
}
@media (min-width: 992px) and (max-width: 1169px) {
  .where__section {
    max-width: 800px;
  }
}
@media (min-width: 768px) and (max-width: 1169px) {
  .where__section {
    margin: 0 auto;
  }
  .where__section:before,
  .where__section:after {
    content: " ";
    display: table;
  }
  .where__section:after {
    clear: both;
  }
  .where__section:before,
  .where__section:after {
    content: " ";
    display: table;
  }
  .where__section:after {
    clear: both;
  }
}
@media (max-width: 767px) {
  .where__section h4 {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .where__section__inner {
    float: left;
  }
}
@media (min-width: 480px) and (max-width: 579px) {
  .where__section__inner--left {
    margin: 0 auto;
    max-width: 390px;
  }
}
@media (min-width: 580px) and (max-width: 767px) {
  .where__section__inner--left {
    margin: 0 auto;
    max-width: 460px;
  }
}
.where__button {
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
}
@media (max-width: 479px) {
  .where__button {
    display: block;
    margin: 0 0 5px;
  }
}
@media (min-width: 480px) {
  .where__button {
    display: inline-block;
    vertical-align: middle;
  }
}
.where__button.active {
  border: 1px solid #f37e33;
  box-shadow: inset 0px 0px 0 1px #f37e33;
}
.where__button span {
  line-height: 1;
  display: block;
  position: relative;
  font-weight: 700;
}
@media (max-width: 579px) {
  .where__button span {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media (min-width: 580px) {
  .where__button span {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.where__button span:before {
  content: '';
  position: absolute;
  top: 50%;
}
@media (max-width: 579px) {
  .where__button--1 span {
    padding: 12px 15px 12px 60px;
  }
}
@media (min-width: 580px) and (max-width: 991px) {
  .where__button--1 span {
    padding: 12px 20px 12px 60px;
  }
}
@media (min-width: 992px) {
  .where__button--1 span {
    padding: 12px 10px 12px 60px;
  }
}
.where__button--1 span:before {
  margin-top: -17.5px;
  background-image: url('../img/town.png');
  width: 35px;
  height: 35px;
  background-size: 35px;
  left: 14px;
}
@media (max-width: 579px) {
  .where__button--2 span {
    padding: 18px 15px 18px 60px;
  }
}
@media (min-width: 580px) {
  .where__button--2 span {
    padding: 19px 25px 19px 60px;
  }
}
.where__button--2 span:before {
  margin-top: -13px;
  background-image: url('../img/transit.png');
  width: 38px;
  height: 26px;
  background-size: 38px 26px;
  left: 12px;
}
@media (max-width: 479px) {
  .where__button--3 span {
    padding: 18px 15px 18px 60px;
  }
}
@media (min-width: 480px) and (max-width: 579px) {
  .where__button--3 span {
    padding: 18px 15px 18px 34px;
  }
}
@media (min-width: 580px) {
  .where__button--3 span {
    padding: 19px 30px 19px 50px;
  }
}
.where__button--3 span:before {
  margin-top: -11px;
  background-image: url('../img/where_question_mark.png');
  width: 13px;
  height: 22px;
  background-size: 13px 22px;
}
@media (max-width: 479px) {
  .where__button--3 span:before {
    left: 25px;
  }
}
@media (min-width: 480px) {
  .where__button--3 span:before {
    left: 18px;
  }
}
.where__button--4 select {
  height: 52px;
  width: 100%;
  -webkit-appearance: none;
  padding-left: 15px;
}
@media (max-width: 479px) {
  .where__button--4 {
    text-align: center;
  }
}
@media (max-width: 479px) {
  .where__button--4 p {
    margin: 7px 0;
  }
}
.found__button--5 {
  text-align: center;
}
.found__button--5 p {
  padding: 6px 20px;
  margin: 0;
  display: block;
  text-align: center;
  font-weight: 700;
}
.found__button--5 p span {
  font-weight: 300;
}
.found__button--5 span {
  font-weight: 300;
}
.where__section__wrap {
  margin: 15px 0 0;
}
.where__section__wrap:before,
.where__section__wrap:after {
  content: " ";
  display: table;
}
.where__section__wrap:after {
  clear: both;
}
.where__section__wrap:before,
.where__section__wrap:after {
  content: " ";
  display: table;
}
.where__section__wrap:after {
  clear: both;
}
@media (max-width: 767px) {
  .where__section__place {
    margin: 15px auto 0;
    max-width: 300px;
  }
}
@media (min-width: 768px) {
  .where__section__place {
    margin-left: 20px;
    float: left;
  }
}
.where__section__place select {
  height: 54px;
  padding-left: 15px;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  .where__section__place select {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .where__section__place select {
    width: 198px;
  }
}
@media (min-width: 992px) {
  .where__section__place select {
    width: 275px;
  }
}
.where__section__send {
  max-width: 300px;
  width: 100%;
}
@media (max-width: 1169px) {
  .where__section__send {
    margin: 0 auto;
  }
}
@media (min-width: 1170px) {
  .where__section__send {
    float: right;
  }
}
.where__section__send button {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1;
  border: none;
  display: block;
  box-sizing: border-box;
  color: #ffffff;
  font-weight: 700;
  border-radius: 4px;
  width: 100%;
  background-color: #f37e33;
}
@media (max-width: 479px) {
  .where__section__send button {
    padding: 13px 20px;
  }
}
@media (min-width: 480px) {
  .where__section__send button {
    padding: 13px 38px;
  }
}
@media (max-width: 1169px) {
  .where__section__send button {
    margin: 15px 0 0;
    width: 100%;
  }
}
@media (min-width: 1170px) {
  .where__section__send button {
    margin: 32px 0 0 0;
  }
}
.where__section__send button span {
  margin: 2px 0 0;
  font-size: 16px;
  font-size: 1rem;
  display: block;
  font-weight: 300;
}
.where__section__send button:disabled {
  background-color: #ccc;
}
.where__section__send button:disabled:hover {
  background-color: #ccc;
}
.where__section__send button:hover {
  background-color: #f16911;
}
.where__button#place:after {
  content: '';
  background-image: url("img/town.png");
  position: relative;
  width: 100%;
}
/* NAŠEL JSEM */
.found h4 {
  line-height: 1;
  font-weight: 700;
  clear: both;
}
@media (max-width: 479px) {
  .found h4 {
    text-align: center;
    padding: 0 10px;
    font-size: 20px;
    font-size: 1.25rem;
  }
}
@media (min-width: 480px) {
  .found h4 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
.found__notification {
  position: relative;
  overflow: auto;
  background-color: #c3e8f6;
}
@media (max-width: 767px) {
  .found__notification {
    text-align: center;
  }
}
.found__notification p {
  font-size: 14px;
  font-size: 0.875rem;
  margin: 0;
  position: relative;
  line-height: 1;
  float: left;
}
@media (max-width: 767px) {
  .found__notification p {
    line-height: 1.2;
    text-align: left;
    padding: 19px 10px 8px 50px;
  }
}
@media (min-width: 768px) {
  .found__notification p {
    padding: 19px 10px 19px 50px;
  }
}
.found__notification:before {
  content: '';
  position: absolute;
  background-image: url('../img/found_notification.svg');
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  background-size: 25px;
  top: 50%;
  margin-top: -12.5px;
  left: 10px;
}
.found__notification a {
  margin: 5px;
  background: #1d99ce;
  color: #ffffff;
  text-decoration: none;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 700;
  border-radius: 3px;
}
@media (max-width: 767px) {
  .found__notification a {
    clear: both;
    display: inline-block;
    padding: 8px 10px;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) {
  .found__notification a {
    float: right;
    padding: 11px 12px;
  }
}
.found__tab__selector__one {
  float: left;
  cursor: pointer;
  z-index: 10;
}
.found__tab__selector__one span {
  padding: 16px 52px;
  border-top: 1px solid #d4d4d4;
  border-right: 1px solid #d4d4d4;
  border-left: 1px solid #d4d4d4;
  border-bottom: 1px solid #e7f4f9;
  font-size: 14px;
  font-size: 0.875rem;
  margin-top: 10px;
  line-height: 1;
  font-weight: 700;
  display: block;
}
.found__tab__selector__one.active {
  background-color: #e7f4f9;
}
.found__tab__selector__one.active span {
  border: 1px solid #e7f4f9;
}
.found__tab__inner {
  clear: both;
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.1);
  background: #e7f4f9;
  z-index: 20;
}
@media (max-width: 579px) {
  .found__tab__inner {
    padding: 15px;
  }
}
@media (min-width: 580px) {
  .found__tab__inner {
    padding: 30px;
  }
}
.found__tab__inner h5 {
  font-size: 20px;
  font-size: 1.25rem;
  display: block;
  margin-top: 0;
  margin-bottom: 30px;
}
.found__tab__inner h6 {
  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: 700;
}
.found__tab__inner h6 span {
  font-size: 24px;
  font-size: 1.5rem;
}
.found__tab__where input,
.found__tab__inner__photo_wrapper input {
  height: 52px;
  border-radius: 4px;
  color: #9A9A9A;
  border: 1px solid #dbdbdb;
  width: 100%;
  padding: 10px 0 10px 14px;
  line-height: 1;
}
.found__tab__inner__row {
  clear: both;
}
.found__tab__inner__photo_wrapper {
  float: left;
  width: 50%;
  position: relative;
}
.found__tab__inner__photo_wrapper p {
  text-align: center;
  color: #ccc;
}
@media (max-width: 479px) {
  .found__tab__inner__photo_wrapper__1 {
    width: 100%;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .found__tab__inner__photo_wrapper__1 {
    width: 52%;
  }
}
@media (min-width: 768px) {
  .found__tab__inner__photo_wrapper__1 {
    width: 63%;
  }
}
.found__tab__inner__photo_wrapper__2 {
  text-align: center;
}
@media (max-width: 479px) {
  .found__tab__inner__photo_wrapper__2 {
    width: 100%;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .found__tab__inner__photo_wrapper__2 {
    width: 48%;
  }
}
@media (min-width: 768px) {
  .found__tab__inner__photo_wrapper__2 {
    width: 37%;
  }
}
.found__tab__inner__photo_wrapper__2 label {
  background-color: #1d99ce;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1;
  position: relative;
  color: #ffffff;
  border-radius: 3px;
  display: inline-block;
  cursor: pointer;
  transition-duration: 0.3s;
}
.found__tab__inner__photo_wrapper__2 label:hover {
  background-color: #1982af;
}
@media (max-width: 479px) {
  .found__tab__inner__photo_wrapper__2 label {
    margin-top: 15px;
  }
}
@media (max-width: 579px) {
  .found__tab__inner__photo_wrapper__2 label {
    padding: 17px 15px 17px 35px;
  }
}
@media (min-width: 580px) {
  .found__tab__inner__photo_wrapper__2 label {
    padding: 17px 30px 17px 50px;
  }
}
.found__tab__inner__photo_wrapper__2 label:before {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  background-image: url('../img/plus_white.svg');
  background-repeat: no-repeat;
  background-size: 15px;
}
@media (max-width: 579px) {
  .found__tab__inner__photo_wrapper__2 label:before {
    left: 14px;
  }
}
@media (min-width: 580px) {
  .found__tab__inner__photo_wrapper__2 label:before {
    left: 28px;
  }
}
.found__where__buttons {
  margin: 0 -4px;
}
.found__button__wrap {
  box-sizing: border-box;
  float: left;
  margin-top: 10px;
}
@media (max-width: 479px) {
  .found__button__wrap--1,
  .found__button__wrap--2 {
    width: 100%;
  }
}
@media (min-width: 480px) and (max-width: 1169px) {
  .found__button__wrap--1,
  .found__button__wrap--2 {
    width: 50%;
  }
}
@media (min-width: 1170px) {
  .found__button__wrap--1,
  .found__button__wrap--2 {
    width: 25%;
  }
}
@media (max-width: 479px) {
  .found__button__wrap--4,
  .found__button__wrap--5 {
    width: 100%;
  }
}
@media (min-width: 480px) and (max-width: 1169px) {
  .found__button__wrap--4,
  .found__button__wrap--5 {
    width: 50%;
  }
}
@media (min-width: 1170px) {
  .found__button__wrap--4,
  .found__button__wrap--5 {
    width: 35%;
  }
}
@media (max-width: 1169px) {
  .found__button__wrap--3 {
    width: 100%;
  }
}
@media (min-width: 1170px) {
  .found__button__wrap--3 {
    width: 50%;
  }
}
.found__button {
  margin: 0 4px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ffffff;
}
@media (max-width: 479px) {
  .found__button {
    display: block;
    margin: 0 0 5px;
  }
}
.found__button span {
  line-height: 1;
  display: block;
  position: relative;
  font-weight: 700;
}
@media (max-width: 579px) {
  .found__button span {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media (min-width: 580px) {
  .found__button span {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.found__button span:before {
  content: '';
  position: absolute;
  top: 50%;
}
.found__button__3 {
  position: relative;
  margin-left: 5px;
}
.found__button__3:before {
  content: 'Upřesnění';
  top: -25px;
  left: 5px;
  position: absolute;
}
.found__button__3 input {
  width: 270px;
}
.found__tab__radio {
  margin-bottom: 45px;
  padding-top: 15px;
}
.found__tab__radio label {
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  cursor: pointer;
  transition-duration: 0.3s;
  line-height: 1;
}
@media (max-width: 479px) {
  .found__tab__radio label {
    padding: 10px 10px;
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media (min-width: 480px) {
  .found__tab__radio label {
    padding: 15px 20px;
  }
}
@media (max-width: 579px) {
  .found__tab__radio label {
    display: block;
    margin-top: 10px;
  }
}
.found__tab__radio label.active {
  background-color: #f37e33;
  color: #ffffff;
  transition-duration: 0.3s;
}
@media (max-width: 579px) {
  .found__tab__radio__one {
    display: block;
  }
}
@media (min-width: 580px) {
  .found__tab__radio__one {
    display: inline-block;
  }
}
.found__tab__inner__row__found_cat {
  overflow: auto;
}
@media (max-width: 479px) {
  .found__tab__inner__row__found_cat {
    max-width: 200px;
    margin: 0 auto;
  }
}
.found__tab__inner__row__found_cat h6 {
  margin-top: 20px;
}
.found__tab__checkbox__row {
  box-sizing: border-box;
  display: flex;
  float: left;
}
@media (max-width: 479px) {
  .found__tab__checkbox__row {
    width: 100%;
  }
}
@media (min-width: 480px) and (max-width: 1169px) {
  .found__tab__checkbox__row {
    width: 50%;
  }
}
@media (min-width: 1170px) {
  .found__tab__checkbox__row {
    width: 33.33%;
  }
}
.found__tab__checkbox__row label {
  font-size: 13px;
  font-size: 0.8125rem;
  width: 100%;
  line-height: 1;
  float: left;
  margin-bottom: 30px;
}
@media (max-width: 579px) {
  .found__continue {
    text-align: center;
  }
}
@media (min-width: 580px) {
  .found__continue {
    text-align: right;
  }
}
.found__continue button {
  position: relative;
  display: inline-block;
  color: #ffffff;
  padding: 19px 52px 19px 19px;
  border-radius: 4px;
  line-height: 1;
  font-size: 17px;
  font-size: 1.0625rem;
  background: #f37e33;
  text-decoration: none;
}
.found__continue button:disabled {
  background-color: #ccc;
}
.found__continue button:disabled:hover {
  background-color: #ccc;
}
.found__continue button:hover {
  background-color: #f16911;
}
.found__continue button:after {
  content: '';
  position: absolute;
  background-image: url('../img/arrow_next.svg');
  background-repeat: no-repeat;
  background-size: 18px 14px;
  width: 18px;
  height: 15px;
  right: 20px;
  top: 50%;
  margin-top: -7.5px;
}
.found__right {
  text-align: center;
}
@media (max-width: 991px) {
  .found__right {
    margin-top: 32px;
  }
}
@media (max-width: 479px) {
  .found__right {
    text-align: center;
  }
}
.found__right img {
  margin-left: 45px;
}
@media (max-width: 479px) {
  .found__right img {
    max-width: 200px;
  }
}
.found__right h4 {
  position: relative;
}
.found__right h4:after {
  position: absolute;
  content: '';
  background-image: url('../img/found_vratilka.svg');
  background-size: 65px 136px;
  width: 65px;
  height: 136px;
  right: 10px;
  top: -120px;
}
/* FOUND BOX 2 */
.found__box__code__headline {
  margin: 0;
}
.found__box__code__subhead {
  font-size: 12px;
  font-size: 0.75rem;
}
@media (max-width: 479px) {
  .found__code {
    text-align: center;
    margin: 0 auto;
  }
}
.found__code input {
  height: 54px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
}
@media (max-width: 479px) {
  .found__code input {
    width: 100%;
  }
}
@media (min-width: 480px) {
  .found__code input {
    float: left;
  }
}
@media (min-width: 480px) and (max-width: 579px) {
  .found__code input {
    width: 150px;
  }
}
@media (min-width: 580px) {
  .found__code input {
    width: 250px;
  }
}
.found__code button {
  position: relative;
  background: #f37e33;
  color: #ffffff;
  padding: 18px 52px 18px 19px;
  border-radius: 4px;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  font-size: 17px;
  font-size: 1.0625rem;
  text-decoration: none;
}
@media (min-width: 480px) {
  .found__code button {
    margin-left: 15px;
  }
}
.found__code button:disabled {
  background-color: #ccc;
}
.found__code button:disabled:hover {
  background-color: #ccc;
}
.found__code button:hover {
  background-color: #f16911;
}
.found__code button:after {
  content: '';
  position: absolute;
  background-image: url('../img/arrow_next.svg');
  background-repeat: no-repeat;
  background-size: 18px 14px;
  width: 18px;
  height: 15px;
  right: 20px;
  top: 50%;
  margin-top: -7.5px;
}
@media (max-width: 479px) {
  .found__code__continue {
    margin-top: 10px;
  }
}
.found__owner {
  clear: both;
}
.found__owner p {
  color: #84c011;
  text-align: center;
  font-size: 24px;
  font-size: 1.5rem;
  display: block;
  padding-top: 10px;
}
@media (max-width: 579px) {
  .found__owner__info__box {
    text-align: center;
  }
}
@media (min-width: 580px) {
  .found__owner__info__box {
    display: inline-block;
    vertical-align: top;
  }
}
@media (min-width: 580px) {
  .found__owner__info__box--2 {
    margin-left: 50px;
  }
}
.found__contact {
  clear: both;
  padding-top: 20px;
}
@media (min-width: 580px) {
  .found__contact__box {
    float: left;
  }
}
.found__contact__box__subhead {
  font-size: 12px;
  font-size: 0.75rem;
  padding-right: 10px;
}
.found__contact__box--1 {
  box-sizing: border-box;
}
@media (max-width: 579px) {
  .found__contact__box--1 {
    width: 100%;
    text-align: center;
  }
}
@media (min-width: 580px) {
  .found__contact__box--1 {
    width: 35%;
  }
}
@media (max-width: 991px) {
  .found__contact__box--2 {
    margin: 0 auto;
  }
}
@media (min-width: 580px) {
  .found__contact__box--2 {
    width: 65%;
    box-sizing: border-box;
  }
}
.found__contact__box__row p {
  box-sizing: border-box;
  margin: 0;
}
@media (max-width: 479px) {
  .found__contact__box__row p {
    padding: 0;
  }
}
@media (min-width: 480px) {
  .found__contact__box__row p {
    float: left;
    width: 20%;
    padding: 13px 0;
  }
}
.found__contact__box__row input {
  height: 52px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  width: 100%;
  padding-left: 15px;
}
.found__contact__box__wrap {
  box-sizing: border-box;
}
@media (min-width: 480px) {
  .found__contact__box__wrap {
    float: left;
    width: 80%;
  }
}
.found__contact__box__wrap a {
  position: relative;
  background: #f37e33;
  color: #ffffff;
  float: right;
  padding: 17px 19px 17px 19px;
  border-radius: 4px;
  line-height: 1;
  font-size: 17px;
  font-size: 1.0625rem;
  text-decoration: none;
}
/*
  THANK YOU PAGE
 */
.found__tab__thankyou {
  text-align: center;
}
.found__tab__thankyou span {
  color: #84c011;
  font-weight: 700;
}
.found__tab__thankyou h5 {
  font-size: 25px;
  font-size: 1.5625rem;
  margin-top: 40px;
}
.found__tab__thankyou p {
  max-width: 550px;
  display: block;
  margin: 0 auto;
}
.found__tab__thankyou__headline {
  font-size: 36px;
  font-size: 2.25rem;
  font-weight: 700;
  text-align: center;
}
.found__tab__thankyou__subhead p {
  font-size: 18px;
  font-size: 1.125rem;
}
.found__tab__email__left span {
  margin: 15px 0;
  display: block;
}
@media (max-width: 579px) {
  .found__tab__email__left span {
    text-align: center;
  }
}
@media (min-width: 580px) {
  .found__tab__email__left span {
    text-align: right;
  }
}
.found__tab__email__left__anonym {
  font-weight: 700;
}
@media (max-width: 579px) {
  .found__tab__email__right {
    text-align: center;
  }
}
.found__tab__email__right input {
  height: 52px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
@media (max-width: 579px) {
  .found__tab__email__right input {
    width: 100%;
  }
}
@media (min-width: 580px) {
  .found__tab__email__right input {
    width: 70%;
  }
}
@media (max-width: 579px) {
  .found__tab__email__cred {
    text-align: center;
  }
}
@media (min-width: 580px) {
  .found__tab__emaiL__first {
    padding-bottom: 70px;
  }
}
@media (min-width: 580px) {
  .found__tab__emaiL__second {
    padding-bottom: 30px;
  }
}
.found__tab__email__actions {
  clear: both;
}
@media (max-width: 579px) {
  .found__tab__email__actions {
    padding-top: 30px;
    text-align: center;
  }
}
@media (max-width: 579px) {
  .found__tab__email__actions div {
    padding-top: 15px;
  }
}
@media (min-width: 580px) {
  .found__tab__email__actions div {
    float: right;
  }
}
.found__tab__email__actions div a {
  margin: 0;
}
.found__tab__email__previous {
  margin: 15px 0;
  padding-left: 22px;
  color: #1d99ce;
  text-decoration: underline;
  cursor: pointer;
  transition-duration: 0.3s;
  position: relative;
}
@media (min-width: 580px) {
  .found__tab__email__previous {
    float: left;
  }
}
.found__tab__email__previous:hover {
  color: #f37e33;
}
.found__tab__email__previous:hover:before {
  opacity: 0;
}
.found__tab__email__previous:hover:after {
  opacity: 1;
}
.found__tab__email__previous:before {
  content: '';
  position: absolute;
  opacity: 1;
  background: url('../img/arrow_next_blue.svg') center center no-repeat;
  background-size: 18px 14px;
  width: 18px;
  height: 14px;
  top: 50%;
  margin-top: -7px;
  left: 0;
  transition-duration: 0.3s;
}
.found__tab__email__previous:after {
  content: '';
  position: absolute;
  opacity: 0;
  background: url('../img/arrow_next_orange.svg') center center no-repeat;
  background-size: 18px 14px;
  width: 18px;
  height: 14px;
  top: 50%;
  margin-top: -7px;
  left: 0;
  transition-duration: 0.3s;
}
.found__tab__email__foot {
  clear: both;
}
@media (max-width: 579px) {
  .found__tab__email__foot {
    padding-top: 40px;
  }
}
/* TOTO JE VRÁTILKA */
.return {
  background-color: #4e4d4d;
  color: #ffffff;
  padding-bottom: 30px;
}
.return h1 {
  font-size: 36px;
  font-size: 2.25rem;
  margin-top: 70px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 30px;
}
.return h2 {
  font-size: 19px;
  font-size: 1.1875rem;
  line-height: 1;
  margin-top: 0;
  font-weight: 700;
  margin-bottom: 5px;
}
.return p {
  font-size: 19px;
  font-size: 1.1875rem;
  font-weight: 400;
}
.return .image {
  margin-top: 40px;
}
.return__right {
  display: block;
  margin-top: 100px;
}
.return__right p {
  line-height: 1.3;
  font-size: 23px;
  font-size: 1.4375rem;
  font-weight: 300;
}
.return__right span {
  line-height: 1;
  font-size: 48px;
  font-size: 3rem;
  font-weight: 700;
  color: #f37e33;
}
#secure {
  background: url('../img/secure_bg.png') center center;
  overflow: hidden;
}
@media (max-width: 479px) {
  #secure {
    padding: 32px 0 0 0;
  }
}
@media (min-width: 480px) {
  #secure {
    padding: 32px 32px 0 32px;
  }
}
@media (max-width: 1169px) {
  #secure {
    padding-bottom: 32px;
  }
}
#secure h1 {
  font-size: 26px;
  font-size: 1.625rem;
  text-align: center;
  font-weight: 700;
}
#secure .image__wrapper {
  overflow: hidden;
  text-align: center;
  margin-top: 30px;
}
@media (min-width: 1170px) {
  #secure .image__wrapper {
    float: left;
    width: 60%;
  }
}
@media (max-width: 1169px) {
  #secure .image__wrapper img {
    max-height: 490px;
    max-width: 100%;
  }
}
@media (min-width: 1170px) {
  #secure .image__wrapper img {
    max-width: 550px;
    max-height: 490px;
  }
}
#secure .list__wrapper {
  margin-top: 80px;
}
@media (min-width: 1170px) {
  #secure .list__wrapper {
    float: left;
    width: 40%;
  }
}
@media (max-width: 1169px) {
  #secure .list__wrapper {
    text-align: center;
  }
}
#secure .list__wrapper ul {
  list-style: none;
  padding: 0;
}
@media (min-width: 1170px) {
  #secure .list__wrapper ul {
    float: right;
  }
}
#secure .list__wrapper ul li {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  width: 372px;
  padding: 12px;
  background-color: #ffffff;
  color: #1d99ce;
  text-decoration: underline;
  margin-bottom: 1px;
  cursor: pointer;
  position: relative;
}
@media (max-width: 1169px) {
  #secure .list__wrapper ul li {
    font-size: 12px;
    font-size: 0.75rem;
    display: inline;
  }
}
@media (max-width: 991px) {
  #secure .list__wrapper ul li:nth-of-type(2n+1) {
    display: none;
  }
}
#secure .list__wrapper ul li.active {
  color: #000000;
  text-decoration: none;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
}
@media (max-width: 1169px) {
  #secure .list__wrapper ul li.active {
    padding: 16px;
    display: inline;
    box-shadow: none;
  }
}
#secure .list__wrapper ul li.active:before {
  content: '';
  position: absolute;
  margin-top: -12px;
  margin-left: -39px;
  background: transparent;
  border-top: 27px solid transparent;
  border-bottom: 27px solid transparent;
  border-right: 27px solid #ffffff;
}
@media (max-width: 1169px) {
  #secure .list__wrapper ul li.active:before {
    top: -31px;
    left: 50%;
    margin-left: -8px;
    border-left: 16px solid transparent;
    border-bottom: 16px solid #ffffff;
    border-right: 16px solid transparent;
  }
}
#secure .list__wrapper button {
  width: 49%;
}
@media (max-width: 1169px) {
  #secure .list__wrapper button {
    width: 250px;
    margin-top: 24px;
  }
}
#secure .list__wrapper button.button__activate {
  background-color: #f37e33;
}
#secure .list__wrapper button.button__buy {
  background-color: #84c011;
}
#sponsors {
  text-align: center;
}
#sponsors img {
  margin: 16px 8px;
}
#projects {
  background-color: #424347;
  color: #ffffff;
  text-align: center;
  padding-bottom: 52px;
}
#projects h1 {
  font-size: 32px;
  font-size: 2rem;
  font-weight: 300;
  margin: 32px 0;
}
#projects a {
  background: transparent;
  border: 1px solid #ccc;
  padding: 14px 42px 14px 21px;
  border-radius: 3px;
  position: relative;
  text-decoration: none;
  color: #ffffff;
}
#projects a:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 6px;
  border-color: transparent transparent transparent #ffffff;
  position: absolute;
  top: 20px;
  display: block;
  right: 18px;
  z-index: 1;
}
#projects a:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 4px;
  border-color: transparent transparent transparent #424347;
  position: absolute;
  top: 22px;
  right: 20px;
  z-index: 2;
}
#projects .project__wrap {
  padding: 9px;
  display: inline-block;
  width: 33%;
  box-sizing: border-box;
}
@media (max-width: 767px) {
  #projects .project__wrap {
    width: 100%;
  }
}
#projects .project__wrap .project__wrap__inner {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 28px 48px;
}
#projects .project__wrap .project__wrap__inner .img__wrap {
  height: 88px;
}
#projects .project__wrap .project__wrap__inner p {
  font-weight: 300;
  margin: 24px 0;
}
.lost__what {
  clear: both;
}
.filter__items {
  background-color: #e7f4f9;
}
.filter__items h2 {
  font-size: 24px;
  font-size: 1.5rem;
  margin-top: 20px;
}
.filter__items span {
  font-weight: 700;
}
.filter__items__wrapper {
  padding-bottom: 30px;
}
.filter__find {
  padding-top: 20px;
}
.filer__find__left {
  border: 1px solid #ccc;
}
.filter__find__left__logo {
  text-align: center;
  padding: 25px 0;
  border-bottom: 1px solid #ccc;
  max-height: 85px;
}
.filter__find__left__info {
  padding: 20px;
}
.filter__find__left__info h3 {
  color: #f37e33;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 700;
}
.filter__find__left__info p {
  font-size: 13px;
  font-size: 0.8125rem;
}
.filter__find__left__info strong {
  font-size: 13px;
  font-size: 0.8125rem;
}
.filter__find__left__info a {
  font-size: 13px;
  font-size: 0.8125rem;
  color: #f37e33;
}
.filter__find__left__info a:hover {
  color: #f16911;
}
.filter__find__open_hours {
  padding-top: 20px;
  display: block;
}
.filter__find__search {
  position: relative;
  background-color: #ccc;
  padding: 10px;
}
.filter__find__search form {
  margin: 0;
}
.filter__find__searchfield input {
  height: 50px;
  border-radius: 4px;
  border: none;
  width: 80%;
  padding-left: 55px;
}
.filter__find__searchfield:before {
  content: '';
  position: absolute;
  background: url('../img/filter-search.svg') no-repeat;
  height: 20px;
  width: 20px;
  top: 50%;
  margin-top: -10px;
  left: 25px;
}
.filter__find__submit {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 50px;
  background-color: #f37e33;
  width: 20%;
  color: #ffffff;
  font-weight: 700;
  border: none;
  border-radius: 4px;
}
.filter__notif {
  padding: 20px;
  margin-top: 10px;
}
.filter__notif p {
  margin: 0;
  line-height: 1;
  font-size: 14px;
  font-size: 0.875rem;
}
.filter__notif--true {
  background-color: #ddf1b6;
  color: #4f6327;
}
.filter__notif--false {
  color: #c1393d;
  background-color: #ffc2c4;
}
.filter__found__button {
  padding: 15px 20px;
  line-height: 1;
}
.filter__found {
  margin-top: 20px;
}
.filter__found li {
  list-style-type: none;
  position: relative;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 0;
  margin: 0;
  display: block;
}
.filter__found li:before,
.filter__found li:after {
  content: " ";
  display: table;
}
.filter__found li:after {
  clear: both;
}
.filter__found li:before,
.filter__found li:after {
  content: " ";
  display: table;
}
.filter__found li:after {
  clear: both;
}
.filter__found li:nth-of-type(even) {
  background-color: #f6f6f6;
}
@media (min-width: 580px) {
  .filter__found__item {
    float: left;
  }
}
@media (min-width: 768px) {
  .filter__found__item {
    width: 70%;
  }
}
.filter__found__item.active {
  width: 100%;
}
@media (max-width: 991px) {
  .filter__found__button {
    text-align: center;
    margin: 0 auto;
    clear: both;
    display: block;
  }
}
@media (min-width: 992px) {
  .filter__found__button {
    position: absolute;
    right: 30px;
    margin-top: -40px;
    max-width: 30%;
    top: 50%;
  }
}
.filter__found__image {
  text-align: center;
}
.filter__found__image.active {
  width: 30%;
  padding: 30px;
  position: relative;
  height: 290px;
}
.filter__found__image.active img {
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 30px;
  max-height: 290px;
  max-width: 90%;
  height: auto;
}
.filter__found__image img {
  height: 120px;
}
@media (min-width: 580px) {
  .filter__found__image img {
    position: absolute;
  }
}
@media (min-width: 580px) {
  .filter__found__image {
    float: left;
  }
}
.filter__found__basic {
  vertical-align: top;
  padding-top: 20px;
  line-height: 1;
  font-size: 19px;
  font-size: 1.1875rem;
  font-weight: 700;
  color: #000000;
}
.filter__found__basic h4 {
  margin-bottom: 12px;
  line-height: 1.2;
  padding: 0;
}
@media (max-width: 579px) {
  .filter__found__basic h4 {
    text-align: center;
  }
}
.filter__found__basic p {
  margin-bottom: 15px;
  line-height: 1;
  font-weight: 400;
}
.filter__found__basic.active {
  padding-left: 30px;
}
@media (min-width: 580px) {
  .filter__found__basic {
    padding-left: 190px;
    float: left;
  }
}
@media (max-width: 579px) {
  .filter__found__infos {
    padding-left: 40px;
    padding-right: 15px;
  }
}
.filter__found__date,
.filter__found__place {
  position: relative;
  color: #4e4d4d;
}
@media (max-width: 579px) {
  .filter__found__date,
  .filter__found__place {
    max-width: 300px;
    margin: 0 auto;
  }
}
@media (min-width: 580px) {
  .filter__found__date,
  .filter__found__place {
    margin-left: 30px;
    padding: 0;
  }
}
.filter__found__date:before,
.filter__found__place:before {
  content: '';
  position: absolute;
  top: 0;
  margin-left: -30px;
  width: 14px;
}
.filter__found__date:before {
  background: url('../img/calendar.png') no-repeat;
  background-size: 14px;
  height: 14px;
}
.filter__found__place:before {
  background: url('../img/place.png') no-repeat;
  background-size: 14px 17px;
  height: 17px;
}
.filter__item__closer {
  position: absolute;
  right: 5px;
  top: 5px;
}
.footer {
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: 300;
  color: #ffffff;
  background-color: #292a2d;
  padding: 20px;
}
@media (max-width: 991px) {
  .footer {
    text-align: center;
  }
}
.footer p {
  margin: 0;
}
@media (min-width: 992px) {
  .footer p {
    float: left;
  }
}
.footer ul {
  list-style: none;
  padding: 0;
  margin: 0 -8px;
}
@media (max-width: 991px) {
  .footer ul {
    margin-bottom: 20px;
  }
}
@media (min-width: 992px) {
  .footer ul {
    float: right;
  }
}
.footer ul li {
  display: inline-block;
  padding: 0;
  margin: 0;
}
.footer ul li a {
  padding: 0 8px;
  color: #ffffff;
  text-decoration: none;
}
/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}
.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020;
}
.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  border-radius: 4px;
}
.fancybox-opened {
  z-index: 8030;
}
.fancybox-opened .fancybox-skin {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}
.fancybox-outer,
.fancybox-inner {
  position: relative;
}
.fancybox-inner {
  overflow: hidden;
}
.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}
.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap;
}
.fancybox-image,
.fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}
.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}
#fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
  background-image: url('../images/fancybox/fancybox_sprite.png');
}
#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060;
}
#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url('../images/fancybox/fancybox_loading.gif') center center no-repeat;
}
.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040;
}
.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url('../images/fancybox/blank.gif');
  /* helps IE */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 8040;
}
.fancybox-prev {
  left: 0;
}
.fancybox-next {
  right: 0;
}
.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden;
}
.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px;
}
.fancybox-next span {
  right: 10px;
  background-position: 0 -72px;
}
.fancybox-nav:hover span {
  visibility: visible;
}
.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important;
}
/* Overlay helper */
.fancybox-lock {
  overflow: hidden !important;
  width: auto;
}
.fancybox-lock body {
  overflow: hidden !important;
}
.fancybox-lock-test {
  overflow-y: hidden !important;
}
.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url('../images/fancybox/fancybox_overlay.png');
}
.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}
.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}
/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050;
}
.fancybox-opened .fancybox-title {
  visibility: visible;
}
.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center;
}
.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
}
.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}
.fancybox-title-inside-wrap {
  padding-top: 10px;
}
.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8);
}
@media (max-width: 579px) {
  .fancybox-nav span {
    visibility: visible;
  }
}
/*Retina graphics!*/
@media only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading,
  .fancybox-close,
  .fancybox-prev span,
  .fancybox-next span {
    background-image: url('../images/fancybox/fancybox_sprite@2x.png');
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/
  }
  #fancybox-loading div {
    background-image: url('../images/fancybox/fancybox_loading@2x.gif');
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/
  }
}
.screenwidth {
  display: none;
}
@media (max-width: 479px) {
  .screenwidth {
    width: 1px;
  }
}
@media (min-width: 480px) and (max-width: 579px) {
  .screenwidth {
    width: 2px;
  }
}
@media (min-width: 580px) and (max-width: 767px) {
  .screenwidth {
    width: 3px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .screenwidth {
    width: 4px;
  }
}
@media (min-width: 992px) and (max-width: 1169px) {
  .screenwidth {
    width: 5px;
  }
}
@media (min-width: 1170px) {
  .screenwidth {
    width: 6px;
  }
}
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}
.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-search--inline {
  float: left;
}
.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}
.select2-results {
  display: block;
}
.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}
.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-results__option[aria-selected] {
  cursor: pointer;
}
.select2-container--open .select2-dropdown {
  left: 0;
}
.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-search--dropdown {
  display: block;
  padding: 4px;
}
.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}
.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}
.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}
.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  height: 54px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 54px;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 54px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}
.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}
.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right;
}
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}
.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}
.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}
.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd;
}
.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}
.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}
.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: -o-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: linear-gradient(to bottom, #fff 50%, #eee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}
.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}
.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
}
.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}
.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: -o-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #fff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
}
.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}
.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}
.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
}
.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}
.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}
.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}
.select2-container--classic .select2-dropdown {
  background-color: #fff;
  border: 1px solid transparent;
}
.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}
.select2-container--classic .select2-dropdown--below {
  border-top: none;
}
.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}
.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}
.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: #fff;
}
.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}
.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}
